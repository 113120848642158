<template>
  <div >
    <v-data-table
      id="table"
      item-key="_id"
      :loading="loading"
      :show-select="showCheckbox"
      class="elevation-2 pb-5"
      :options.sync="options"
      v-model="selectedQuerylogs"
      @click:row="displayQuerylogDetails"
      @update:options="loadData"
      :headers="headers"
      :items="items"
      :server-items-length="totalItems"
      :items-per-page="itemsPerPage"
      :footer-props="{
        'items-per-page-options': itemsPerPageLIst,
        'items-per-page-text': 'Items per page',
        'show-current-page': true,
        'show-first-last-page': true,
        'hide-default-footer': true,
      }"
      :server-data="loadData"
      hide-default-footer
    >
      <template v-slot:top="{ pagination, options, updateOptions }">
        <v-card flat>
          <v-card-title style="background: #ebebeb">
            {{ title }}
            <v-menu v-if="actions.length > 0" v-model="showMenu" offset-y>
              <template v-slot:activator="{ on }">
                <v-btn
                  :disabled="!isActionButtonEnabled()"
                  color="primary"
                  class="ml-4 mt-1"
                  v-on="on"
                >
                  {{ actionsButtonText }}
                </v-btn>  
              </template>              
              <v-list>
                <v-dialog
                  v-for="(action, index) in actions"
                  :key="index"
                  v-model="dialogsDisplayIndicators[index]"
                  max-width="500px"
                >
                  <template v-slot:activator="{ on }">
                    <v-list-item v-on="on" link>
                      {{ action.title }}
                    </v-list-item>
                  </template>

                  <component
                    :is="actionTypeToComponentMap[action.type]"
                    :data="{ selectedQuerylogs, ...action.args }"
                    @submit="submitAction(action.onSubmit, $event)"
                    @close="closeDialog"
                    @noFiles="closeDialog"
                  />
                </v-dialog>
              </v-list>
            </v-menu>

            <v-spacer />
          </v-card-title>

          <v-card-title v-if="filter">
            <v-select
              label="Filter by analyst"
              :items="workersNames"
              v-model="usernameFilter"
              @change="loadData(options)"
              clearable
              solo
              item-text="username"
              item-value="username"
              class="mr-5"
              style="max-width: 300px"
            />
            <v-select
              label="Filter by time frame"
              :items="timeframeFilterItems"
              @change="loadData(options)"
              v-model="timeframeFilter"
              :disabled="!usernameFilter"
              clearable
              solo
              style="max-width: 300px"
            />
          </v-card-title>
          <v-card-title>
            <v-select
              v-if="options.groupBy"
              label="Group by"
              v-model="options.groupBy[0]"
              :items="groupByHeaders"
              clearable
            >
            </v-select>
          </v-card-title>
          <div>
            <v-data-footer
              :pagination="pagination"
              :options="options"
              @update:options="updateOptions"
              :items-per-page-options="itemsPerPageLIst"
              :show-current-page=true
              :show-first-last-page=true
              items-per-page-text="Items per page"
            >
          </v-data-footer>
          </div>
        </v-card>
        <v-divider />
      </template>

      <template v-slot:group.header="{ group, headers, toggle, isOpen }">
        <td v-if="options.groupBy[0]" :colspan="headers.length">
          <v-btn @click="toggle" x-small icon :ref="group">
            <v-icon v-if="isOpen">mdi-minus</v-icon>
            <v-icon v-else>mdi-plus</v-icon>
          </v-btn>
          <span class="mx-5 font-weight-bold">{{ group }}</span>
        </td>
      </template>

      <template v-slot:item.time="{ value }">
        <span>
          {{ formatTime(value) }}
        </span>
      </template>

      <template v-slot:item.status="{ value }">
        <span :class="value"> {{ value }} </span>
      </template>

      <template v-slot:item.submissionHistory="{ item }">
        <v-btn @click.stop="openHistoryModal(item)"
          >View History
          <v-icon
            style="font-size: 20px"
            v-if="
              item.submissionHistory &&
              item.submissionHistory.some((history) => history.files.length > 0)
            "
            >mdi-paperclip</v-icon
          >
        </v-btn>
      </template>

      <template #item.updatedAt="{ value }">
        <span>{{
         formatTime(value)
        }}</span>
      </template>

      <template #item.workerSubmissionDate="{ value }">
        <span> {{ formatTime(value) }} </span>
      </template>

      <template #item.supervisorCloseDate="{ value }">
        <span> {{ formatTime(value) }} </span>
      </template>

      <template v-slot:item.timeUnassigned="{ value }">
        <v-chip v-if="value >= timeRangeCut" color="red" dark>
          {{ value }}
        </v-chip>
        <v-chip v-else>
          {{ value }}
        </v-chip>
      </template>

      <template v-slot:item.timePending="{ value }">
        <v-chip v-if="value >= timeRangeCut " color="red" dark>
          {{ value }}
        </v-chip>
        <v-chip v-else>
          {{ value }}
        </v-chip>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import moment from "moment";
import shared from '@/utils/shared'

import { calcDiffBetweenDates } from "@/utils/dates";

import { createNamespacedHelpers } from "vuex";
import { QUERY_LOG_FETCH_ITEM_REQUEST } from "@/store/actions/queryLog";
import { format } from "@/utils/dates";
import supervisionClient from "@/services/supervisionClient";

const { mapGetters, mapActions } = createNamespacedHelpers("queryLog");

export default {
  props: {
    title: String,
    headers: Array,
    groupByHeaders: Array,
    actionButtons: Array,
    actions: {
      type: Array,
      default: () => [],
    },
    actionsButtonText: {
      type: String,
      default: "Choose action",
    },
    actionTypeToComponentMap: Object,
    filter: {
      type: Boolean,
      default: false,
    },
    status: String,
    workers: Array,
    wholeItem: Object,
    queryType: String
  },
  data() {
    return {
      showCheckbox: true,
      userRole: this.$store.getters.userRole,
      options: {
        page: 1,
        pageStart: 1,
        sortBy: ['time'],
        sortDesc: [false]
      },
      dialogsDisplayIndicators: [],
      selectedQuerylogs: [],
      showMenu: false,
      usernameFilter: "",
      timeframeFilter: "",
      time_range: parseInt(localStorage.getItem("timeFrameInMinutes")) || 180,
      loading: true,
      itemsPerPage: 10,
      itemsPerPageLIst: [10, 25, 50, 100],
      items: [],
      totalItems: 0,
      skip: 0
    };
  },
  computed: {
    ...mapGetters({
      querylogItems: "result",
    }),
   
    timeRangeCut() {
      return this.time_range / 60
    },
    workersNames() {
      return this.workers.map((worker) => {
        return worker.username;
      });
    },
    timeframeFilterItems() {
      return [
        {
          text: `Less than ${shared.timeframeString(this.time_range)}`,
          value: "newer",
        },
        {
          text: `More than ${shared.timeframeString(this.time_range)}`,
          value: "older",
        },
      ];
    },
  },
  methods: {
    ...mapActions({
      fetchAmlQuerylog: QUERY_LOG_FETCH_ITEM_REQUEST,
    }),
    getLocalOption() {
      const localOptions = JSON.parse(localStorage.getItem(`${this.status}_options`))
      if (localOptions) {
        this.options = localOptions
      }
    },
    setLocalOptions() {
      const stringfiedOptions = JSON.stringify(this.options)
      localStorage.setItem(`${this.status || this.queryType}_options` , stringfiedOptions);
    },
    async loadData({page , sortDesc ,itemsPerPage , sortBy }) {
      this.setLocalOptions()
      let workerIdForFilter = ''
      let newer = ''

      if (this.timeframeFilter) {
        newer = this.timeframeFilter === 'newer' ? 1 : 0
      } else {
        newer = ''
      }
      

      if (this.usernameFilter) {
        const filteredUser = this.workers.filter(worker => worker.username == this.usernameFilter)
        workerIdForFilter = filteredUser[0]._id
      }

      this.loading = true
      let {user , status , skip} = this
      skip = (page - 1) * itemsPerPage

      const response = await supervisionClient.getUserQuerylogs(user.userId ,status ,itemsPerPage , skip ,  sortBy[0] , sortDesc , workerIdForFilter , this.time_range ,newer , this.queryType)
      
      this.items = this.submittedOrPendingItemsConstructor(response.data.queries)

      for (const item of this.items) {

        if (item.incomingTransactionFullData) {
          const parsedData = shared.getMidAndExtRef(item)
          item.extRef = parsedData.externalIdRef[0]
          item.paymentAmount = shared.parseTransactionAmount(item.incomingTransactionFullData)
        } 
      }

      this.totalItems = response.data.countQuery
      this.loading = false
      return this.items
    },
    submittedOrPendingItemsConstructor(queriesItems) {
      const currentTime = moment();
      return queriesItems.map((querylog) => ({
        ...querylog,
        timePending: calcDiffBetweenDates(currentTime, querylog.time),
      }));
    },
    openHistoryModal(item) {
      this.$emit("history", item);
    },
    isActionButtonEnabled() {
      return (
        this.selectedQuerylogs.length > 0 &&
        !this.selectedQuerylogs.some(
          (queryLog) => queryLog.status == "alerted" && this.userRole == "ANALYST"
        )
      );
    },
    submitAction(callback, argsArray) {
      callback(...argsArray);
      this.closeDialog();
      this.selectedQuerylogs = [];
      // this will rerender all tables
      this.$emit('updateTables')
    },
    resetDialogsDiaplyIndicators() {
      this.dialogsDisplayIndicators = Array(this.actions.length).fill(false);
    },
    closeDialog() {
      this.resetDialogsDiaplyIndicators();
      this.showMenu = false;
    },
    async displayQuerylogDetails(item) {
      
      this.$router.push({
        name: "queryLogDetails",
        params: { item, id: item._id },
      });

    },
    formatTime(time) {
      return format(time);
    },
  },
  created() {
    this.getLocalOption()
    this.user = {
      userRole: this.$store.getters.userRole, 
      tenantId: this.$store.getters.tenantId, 
      userId: this.$store.getters.userId
    };

    this.resetDialogsDiaplyIndicators();
    this.showCheckbox = this.$store.getters['users/actionRoles'].includes(this.$store.getters.userRole);
  },
};
</script>

<style scoped>
span.flagged {
  color: red;
}
span.verified {
  color: green;
}
</style> 
