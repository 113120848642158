export const readFileAsBlob = async (file) => new Promise((resolve, reject) => {
  const reader = new FileReader();

  reader.onload = () => resolve(reader.result);
  reader.onabort = reject;

  if (file) {
    reader.readAsDataURL(file);
  } else {
    reject();
  }
});

export const saveDownload = async (response, asFileName) => {
  const url = window.URL.createObjectURL(new Blob([response.data],
    { encoding: 'UTF-8', type: 'text/csv;charset=UTF-8' }));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', asFileName);
  document.body.appendChild(link);
  link.click();
};
