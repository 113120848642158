<template>
  <v-container class="align">
    <v-tabs bg-color="primary">
      <v-tab value="global" @click="getAllChiefQueris">Global</v-tab>
      <v-tab
        v-for="supervisor in supervisors"
        :key="supervisor._id"
        :value="supervisor.username"
        @click="() => getSupervisorData(supervisor.id)"
      >
        {{ `${supervisor.firstname} ${supervisor.lastname}` }}</v-tab
      >
    </v-tabs>
    <progress-bar v-if="!ready" style="width: 93%;"></progress-bar>

    <div v-else-if="chosenChiefSupervisorId">
      <div v-if="querylogs.length">
        <SupervisorReportManagerWrapper
          :querylogs="querylogs"
          :workers="supervisorWorkers"
        />
      </div>
      <div v-else class="no-data d-flex align-center justify-center">
        <h2>No data to show</h2>
      </div>
    </div>
    <div v-else-if="querylogs.length">
      <SupervisorReportManagerWrapper
        :querylogs="querylogs"
        :workers="supervisorWorkers"
        :persistanceLogsNun="persistanceLogsNun"
        :chiefDashboard="true"
      />
    </div>
    <div v-else>
      <h3>No data to show</h3>
    </div>
  </v-container>
</template>

<script>
import SupervisorReportManagerWrapper from "@/components/supervision/reports/SupervisorReportManagerWrapper";
import supervisionClient from "@/services/supervisionClient";
import apiClient from '@/services/apiClient';
import { Labels, autoClosed } from "@/helpers/dataLabels.js"

export default {
  data() {
    return {
      supervisorWorkers: [],
      chosenChiefSupervisorId: "",
      querylogs: [],
      ready: false,
      persistanceLogsNun: 0
      // tab: null,
    };
  },
  props: {
    supervisors: {
      type: Array,
      required: true,
    },
    workers: {
      type: Array,
      required: true,
    },
  },
  components: {
    SupervisorReportManagerWrapper,
  },
  methods: {
    async getSupervisorData(id) {
      this.chosenChiefSupervisorId = id;
      this.supervisorWorkers = this.workers.filter(
        (worker) => worker.managerId == id
      );
      await this.loadData(id);
    },
    async getAllChiefQueris() {
      this.chosenChiefSupervisorId = "";

      const currentUserId = localStorage.getItem("user-id");
      await this.loadData(currentUserId);
    },
    async loadData(id) {
      this.ready = false;
      this.supervisorWorkers = [];
      this.querylogs = [];

      const response = await supervisionClient.getStatisticForChiefOrSupervisor(
        {
          user: id,
          status: "",
        }
      );
      
      // When changing this boolean parameters, make sure to change in PersistanceReportTable as well
      const persistenceResponse = await apiClient.getQueryLog({
        showAutoVerified: true,
        hideEmptyResults: false,
      });
      
      const persistanceData = persistenceResponse.data
      if (persistanceData.length) {
        this.persistanceLogsNun = persistanceData[0].metadata.total
      }
      this.querylogs = this.chosenChiefSupervisorId
        ? response.data.filter((query) => query.finalStatus !== Labels.CLOSED)
        : response.data;

      this.querylogs = this.querylogs.map(querylog => {
        const autoSearchString = 'search@'
        let returnName = ''
        const workerName = querylog.workerName[0].username
        returnName = workerName.includes(autoSearchString) ? autoClosed : querylog.workerName[0].username;
        querylog.workerName[0].username = returnName;

        return querylog
      })

      this.ready = true;
    },
  },
  created() {
    this.getAllChiefQueris();
  },
};
</script>

<style scoped>
.no-data {
  height: 80vh;
  width: 100vw;
  text-align: center;
}
</style>