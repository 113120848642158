<template>
  <v-container fluid>
    <v-row v-for="finalStatus in finalStatuses" :key="finalStatus.status" class="ma-2">
      <v-col>
        <SupervisionQuerylogsTable
          :status="finalStatus.status"
          :headers="finalStatus.headers"
          :groupByHeaders="finalStatus.groupByHeaders"
          :workers="finalStatus.workers"
          @history="finalStatus.history"
          :title="finalStatus.title"
          :wholeItem="finalStatus"
          :queryType="finalStatus.queryType"
        />
      </v-col>
    </v-row>

    <v-dialog width="unset" class="d-flex" v-model="openHistory">
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title>Action history</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="openHistory = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <query-history :queryHistory="queryHistory" />
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import SupervisionQuerylogsTable from "./SupervisionQuerylogsTable";
import queryHistory from "./query-history.vue";
import { Labels } from "@/helpers/dataLabels.js";

export default {
  components: {
    SupervisionQuerylogsTable,
    queryHistory,
  },
  props: {
    baseHeaders: {
      type: Array,
      default: () => [],
    },
    amlHeaders: {
      type: Array,
      default: () => [],
    },
    workers: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      openHistory: false,
      queryHistory: null,
      groupByHeaders: [
        {
          text: "Assigned analyst",
          value: "workerName[0].username",
          sortable: false
        },
        {
          text: "Searched name",
          value: "searchedName",
        },
      ],
      finalStatuses: [
        {
          status: Labels.OPEN,
          headers: this.openQuerylogHeaders(),
          groupByHeaders: this.groupByHeaders,
          workers: this.workers,
          history: this.showHistory,
          title: "Opened",
        },
        {
          status: Labels.REOPENED,
          headers: this.reopenedQuerylogHeaders(),
          groupByHeaders: this.groupByHeaders,
          workers: this.workers,
          history: this.showHistory,
          title: "Reopened",
        },
        {
          status: Labels.PENDING,
          headers: this.pendingQuerylogHeaders(),
          groupByHeaders: this.groupByHeaders,
          filter: false,
          history: this.showHistory,
          workers: this.workers,
          title: "Pending",
        },
        {
          status: Labels.SUBMITTED,
          headers: this.submittedQuerylogHeaders(),
          groupByHeaders: this.groupByHeaders,
          workers: this.workers,
          history: this.showHistory,
          title: "Waiting for approval",
        },
        {
          queryType: "AML",
          filter: true,
          headers: this.amlHeaders,
          groupByHeaders: this.groupByHeaders,
          workers: this.workers,
          history: this.showHistory,
          title: "AML",
        },
      ],
    };
  },
  methods: {
    showHistory(item) {
      this.openHistory = !this.openHistory;
      this.queryHistory = item.submissionHistory;
    },
    reopenedQuerylogHeaders() {
      const combinedHeaders = [...this.baseHeaders];
      combinedHeaders.splice(0, 0, {
        text: "Assigned analyst",
        value: "workerId.username",
        sortable: false
      });
      combinedHeaders.splice(3, 0, {
        text: "Submission History",
        value: "submissionHistory",
      }, 
      {
      text: "Time pending (hours)",
      value: "timePending",
      });

      return combinedHeaders;
    },
    pendingQuerylogHeaders() {
      const combinedHeaders = [...this.baseHeaders];

      combinedHeaders.splice(3, 0, {
        text: "Time pending (hours)",
        value: "timePending",
      });

      combinedHeaders.splice(0, 0, {
        text: "Assigned analyst",
        value: "workerName[0].username",
        sortable: false
      });

      return combinedHeaders;
    },
    submittedQuerylogHeaders() {
      const combinedHeaders = [...this.baseHeaders];

      combinedHeaders.splice(0, 0, {
        text: "Assigned Analyst",
        value: "workerId.username",
        sortable: false
      });

      combinedHeaders.splice(3, 0, {
        text: "Time pending (hours)",
        value: "timePending",
      });

      combinedHeaders.splice(
        4,
        0,
        {
          text: "Worker submission date",
          value: "updatedAt",
        },
        {
          text: "Submission History",
          value: "submissionHistory",
        }
      );

      return combinedHeaders;
    },
    openQuerylogHeaders() {
      const combinedHeaders = [...this.baseHeaders];

      combinedHeaders.splice(
        3,
        0,
      {
        text: "Time pending (hours)",
        value: "timePending",
      });

      return combinedHeaders;
    },
  },
};
</script>

<style>
.v-list-item.primary--text.v-list-item--active.v-list-item--link .v-list-item__title {
  color: #fff;
}
</style>
