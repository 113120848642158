import ChartJs from "chart.js";
import { Doughnut, mixins } from "vue-chartjs";

export default {
  extends: Doughnut,
  mixins: [mixins.reactiveProp],
  props: {
    title: {
      type: String,
      default: "",
    },
    // legend: Boolean,
  },
  data() {
    return {
      options: {
        legend: {
          display: this.legend,
        },
        layout: {
          margin: {
             left: 100  //set that fits the best
          }
       },
        title: {
          display: true,
          text: (this.title !== 'Submitted' ? this.title : 'Waiting for approval') || 'chart data' ,
        },
        onHover: (event, chartElement) => {
          if (chartElement.length) {
            event.target.style.cursor = "pointer";
          } else {
            event.target.style.cursor = "default";
          }
        },
        responsive: true,
        maintainAspectRatio: false,
        onClick: this.handleClick,
        tooltips: {
          // Disable the on-canvas tooltip
          enabled: false,
          
          // This is important for us! the tooltip is cut if we're using the default one
          custom: function(tooltipModel) {
              // Tooltip Element
              let tooltipEl = document.getElementById('chartjs-tooltip');
  
              // Create element on first render
              if (!tooltipEl) {
                tooltipEl = document.createElement('div');
                tooltipEl.id = 'chartjs-tooltip';
                tooltipEl.innerHTML = '<table></table>';
                tooltipEl.style.cssText = 'background-color: #525151; color: #FFFFFF; border-radius: 10px';
                document.body.appendChild(tooltipEl);
              }
              
  
              // Hide if no tooltip
              if (tooltipModel.opacity === 0) {
                  tooltipEl.style.opacity = 0;
                  return;
              }
  
              function getBody(bodyItem) {
                  return bodyItem.lines;
              }
  
              // Set Text
              if (tooltipModel.body) {
                  let titleLines = tooltipModel.title || [];
                  let bodyLines = tooltipModel.body.map(getBody);
  
                  let innerHtml = '<thead>';
  
                  titleLines.forEach(function(title) {
                      innerHtml += '<tr><th>' + title + '</th></tr>';
                  });
                  innerHtml += '</thead><tbody>';
  
                  bodyLines.forEach(function(body , i) {
                      let colors = tooltipModel.labelColors[i];
                      let style = 'background:' + colors.backgroundColor;
                      // style += '; border-color:' + colors.borderColor;
                      // style += '; border-width: 15px';
                      let span = '<span style="' + style + '"></span>';
                      innerHtml += '<tr><td>' + span + body + '</td></tr>';
                  });
                  innerHtml += '</tbody>';
  
                  let tableRoot = tooltipEl.querySelector('table');
                  tableRoot.innerHTML = innerHtml;
              }
  
              // `this` will be the overall tooltip
              let position = this._chart.canvas.getBoundingClientRect();
  
              // Display, position, and set styles for font
              tooltipEl.style.opacity = 1;
              tooltipEl.style.position = 'absolute';
              tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
              tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
              tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
              tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
              tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
              tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
              tooltipEl.style.pointerEvents = 'none';
          }
      } 
      },
    };
  },
  computed: {
    totalQuerylogsAmount() {
      return (
        this.chartData.datasets[0]?.data.reduce(
          (sum, statusQuerylogsAmount) => sum + statusQuerylogsAmount,
          0
        ) || 0
      );
    },
  },
  methods: {
    centerTextPlugin(chart) {
      if (this.totalQuerylogsAmount === 0) {
        return;
      }

      const { width, height, ctx } = chart.chart;

      chart.canvas.parentNode.style.height = '16vw';
      chart.canvas.parentNode.style.width = '16vw';

      ctx.restore();
      const fontSize = (height / 105 / 2).toFixed(2);
      ctx.font = `${fontSize}em sans-serif`;
      ctx.textBaseline = "middle";

      const text = `Total: ${this.totalQuerylogsAmount}`;
      const textX = Math.round((width - ctx.measureText(text).width) / 2);
      const textY = height / 1.7;

      ctx.fillText(text, textX, textY);
      ctx.save();
    },
    handleClick(_event, point) {
      if (point[0]) {
        let tooltipEl = document.getElementById('chartjs-tooltip');
        tooltipEl.remove()
        this.$emit("click", {
          index: point[0]._index,
          datalabel: point[0]._model.label,
        });
      }
    },
  },
  mounted() {
    ChartJs.defaults.global.defaultFontSize = 22;
    this.addPlugin({
      id: "center-text-plugin",
      beforeDraw: this.centerTextPlugin,
    });
    if (this.chartData.datasets[0].data[0] || this.chartData.datasets[0].data[1]) {
      // console.log("this.chartData, this.options :>> ", this.chartData, this.options);
      this.renderChart(this.chartData, this.options);
    }
  },
};
