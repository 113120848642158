<template>
  <br />
</template>

<script>
import { mapGetters } from "vuex";
import downloadReportMixin from "@/components/supervision/mixins/downloadDailyReport";

export default {
  mixins: [downloadReportMixin],
  props: {
    userRole: String,
  },
  computed: {
    ...mapGetters({
      userId: "userId",
    }),
  },
  async mounted() {
    await this.downloadDailyReport({ userRole: this.userRole, userId: this.userId });
  },
};
</script>
