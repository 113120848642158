<template>
  <v-container class="align" v-if="querylogs.length">
    <v-row class="mt-2">
        <v-btn color="primary" class="mb-2 mr-5" @click="exportToFile">
          <v-icon class="mr-2">play_for_work</v-icon> Statistics
        </v-btn>
        <DailyReportModal v-if="chiefDashboard" />
    </v-row>
    
    <SupervisionReportManager
      :groupedQuerylogs="groupedQuerylogs"
      :chart-colors="chartColors"
      :legends="['open', 'timeframe']"
      :workers="workers"
    >
      <template v-slot:external-legend>
        <ChartColorsLegend
          :workers="workers"
          :colors="chartColors"
          :charts="['pending', 'submitted', 'closed']"
        />
      </template>
    </SupervisionReportManager>
  </v-container>
  <v-container v-else >
    No Data to show
  </v-container>
</template>

<script>
// import { saveDownload } from '@/utils/files';
// import shared from '@/utils/shared'
import { groupBy } from "lodash";
import moment from "moment";
import shared from '@/utils/shared'
import { calcDiffBetweenDates } from "@/utils/dates";
import SupervisionReportManager from "@/components/supervision/reports/SupervisionReportManager";
import ChartColorsLegend from "@/components/supervision/reports/chart/ChartColorsLegend";
import { Labels , labelsList } from "@/helpers/dataLabels.js"
import DailyReportModal from "@/components/supervision/reports/DailyReportModal.vue"

export default {
  components: {
    SupervisionReportManager,
    ChartColorsLegend,
    DailyReportModal
  },
  props: {
    querylogs: {
      type: Array,
      default: () => [],
    },
    persistanceLogsNun: {
      type: Number,
      default: 0
    },
    workers: {
      type: Array,
      default: () => [],
    },
    chiefDashboard: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      groupedQuerylogs: {
        reopened: {},
        open: {},
        pending: {},
        submitted: {},
        closed: {},
        timeframe: {},
      },
      time_range: parseInt(localStorage.getItem("timeFrameInMinutes")) || 180,
      workerColors: [150, 150, 150],
    };
  },
  computed: {
    more_then() {
      return `Closed after more than ${shared.timeframeString(this.time_range)}`;
    },
    less_then() {
        return `Closed after less than ${shared.timeframeString(this.time_range)} `;
    },
    open_more_then() {
      return `Open more than ${shared.timeframeString(this.time_range)} `
    },
    groupedQuerylogsByStatus() {
      return groupBy(this.querylogs, "finalStatus");
    },
    timeColorMap() {
      const timeColors = {};
      timeColors[this.more_then] = "rgb(242, 132, 130)"; // "rgb(156,0,156)";
      timeColors[this.open_more_then] = "rgb(225,61,29)"
      timeColors[this.less_then] = "rgb(144, 238, 144)"; // "rgb(96,48,96)";
      return timeColors;
    },
    workerColorsMap() {
      const nonOpenQuerylogs = this.querylogs.filter((querylog) => querylog.finalStatus !== Labels.OPEN);
      const groupedQuerylogs = this.groupQuerylogsByWorker(nonOpenQuerylogs);
      const colorMap = Object.keys(groupedQuerylogs).reduce(
        (res, workerUsername, idx) => ({
          ...res,
          [workerUsername]:
            "rgba(" +
            this.workerColors
              .map((axis, axisIndx) => {
                if (axisIndx === idx % 3) {
                  return axis;
                }
                if (idx % 2) {
                  axis += (15 * idx) % 256; //so it wont exceed 256 in rgb
                } else {
                  axis -= (15 * idx) % 256; //so it wont exceed 256 in rgb
                }
                return axis;
              })
              .join(",") +
            ",0.75)",
        }),
        0,
        {}
      );

      return colorMap;
    },
    chartColors() {
      return {
        open: {
          open: "rgb(238, 218, 0)",
        },
        pending: this.workerColorsMap,
        reopened: this.workerColorsMap,
        submitted: this.workerColorsMap,
        closed: this.workerColorsMap,
        timeframe: this.timeColorMap,
        persistence: {
          persistence: 'rgb(150,211,226)'
        }
      };
    },
  },
  methods: {
    groupQuerylogs() {
      labelsList.forEach((datalabel) => {

        this.groupedQuerylogs[datalabel] = this.groupedQuerylogsByStatus[datalabel];
      });

      this.groupedQuerylogs['persistence'] = {persistence: Array(this.persistanceLogsNun)}
      
      const querylogsByTimeframe = {};
      querylogsByTimeframe[this.more_then] = [];
      querylogsByTimeframe[this.open_more_then] = []
      querylogsByTimeframe[this.less_then] = [];
     
      const currentTime = moment();

      const timeRangeInHours = this.time_range / 60
      for (const querylog of this.querylogs) {

        const isFinalStatusClose = querylog.finalStatus === Labels.CLOSED
        const querylogHoursAge = isFinalStatusClose ?
               calcDiffBetweenDates(moment(querylog.updatedAt), querylog.time) 
             : calcDiffBetweenDates(currentTime, querylog.time);
        const isOverThreshold = parseFloat(querylogHoursAge) > timeRangeInHours
        const openMoreThen = querylog.finalStatus !== Labels.CLOSED && isOverThreshold
        const closedMoreThen = querylog.finalStatus === Labels.CLOSED  && isOverThreshold

        if (openMoreThen) {
          querylogsByTimeframe[this.open_more_then].push({
            ...querylog,
            timeframe: { newer: false },
          }); 
        } else if (closedMoreThen) {
          querylogsByTimeframe[this.more_then].push({
            ...querylog,
            timeframe: { newer: false },
          });
        } else if (querylog.finalStatus === 'closed') {
          querylogsByTimeframe[this.less_then].push({
            ...querylog,
            timeframe: { newer: true },
          });
        }
      }

      this.groupedQuerylogs.timeframe = querylogsByTimeframe;
    },

    modifyQuerylogs() {
      this.groupedQuerylogs.open = this.groupQuerylogsByDatalabel(
        this.groupedQuerylogs.open,
        Labels.OPEN
      );

      for (const datalabel of [ Labels.PENDING, Labels.REOPENED , Labels.SUBMITTED , Labels.CLOSED ]) {
        this.groupedQuerylogs[datalabel] = this.groupQuerylogsByWorker(
          this.groupedQuerylogs[datalabel]
        );
      }
    },
    groupQuerylogsByWorker(querylogs = []) {
      if (querylogs.length === 0) {
        return querylogs;
      }

      return groupBy(querylogs, "workerName[0].username");
    },
    groupQuerylogsByDatalabel(querylogs, datalabel) {
      return {
        [datalabel]: querylogs,
      };
    },
    exportToFile() {
      const dataToExport = this.groupedQuerylogs;
      const rows = [];
      const users = {};
      const titles = ["username", ...Object.keys(dataToExport)];
      const timeframe = dataToExport.timeframe;
      const openedQL = dataToExport.open;

      titles.pop();
      titles.push(...Object.keys(timeframe));
      delete dataToExport.timeframe;
      delete dataToExport.open;

      for (const [key, value] of Object.entries(dataToExport)) {
        for (const [innerKey, innerValue] of Object.entries(value)) {
          if (innerValue) {
            // console.log(`CAT: ${key} - NAME: ${innerKey} VAL: ${Object.keys(innerValue).length}`);
            if (!users[innerKey]) {
              users[innerKey] = [0, 0, 0, 0, 0, 0, 0, 0];
            }
            if (titles.indexOf(key) > 0) {
              users[innerKey][titles.indexOf(key)] = Object.keys(innerValue).length;
            }
          }
        }
      }

      rows.push(titles);

      for (const [key, value] of Object.entries(users)) {
        // console.log(`${key}: ${value}`);
        value.shift();
        rows.push([key, ...value]);
      }

      const globalData = ["global", 0, 0, 0, 0, 0, 0, 0];

      if (timeframe) {
        for (const [key, value] of Object.entries(timeframe)) {
          globalData[titles.indexOf(key)] = value.length;
        }
      }


      if (openedQL && openedQL.open && openedQL.open.length) {
        globalData[titles.indexOf(Labels.OPEN)] = openedQL.open.length;
      }

      rows.push(globalData);

      if (rows.length) {
        // const fileName = `FincomReport_${shared.currentDate(true)}.cvs`;

        let csvContent = "data:text/csv;charset=utf-8,";
        // csvContent += new Date() + "\r\n";
        for (const rowArray of rows) {
          let row = rowArray.join(",");
          csvContent += row + "\r\n";
        }

        //saveDownload(csvContent, fileName);
        const encodedUri = encodeURI(csvContent);
        window.location.assign(encodedUri);
      }
    },
  },
  created() {
    this.groupQuerylogs();
    this.modifyQuerylogs();
  },
};
</script>
