<template>
  <ChiefSupervisorReportManagerWrapper v-if="ready" :supervisors="supervisors" :workers="workers" />
</template>

<script>
import { mapGetters } from "vuex";
import ChiefSupervisorReportManagerWrapper from "@/components/supervision/reports/ChiefSupervisorReportManagerWrapper";

import supervisionClient from "../../../services/supervisionClient";

export default {
  components: {
    ChiefSupervisorReportManagerWrapper,
  },
  data() {
    return {
      workers: [],
      ready: false,
    };
  },
  computed: {
    ...mapGetters({
      loggedinSupervisorId: "userId",
    }),
  },
  async created() {
    this.workers = await this.$store.dispatch({
      type: "getSupervisorWorkers",
      supervisor: this.loggedinSupervisorId,
    });

    const { data } = await supervisionClient.getSupervisors();
    this.supervisors = data;
    this.ready = true
  },
};
</script>
