<template>
    <div>
        <v-alert v-if="isMessage" :color="msgType" dark> {{ messageText }} </v-alert>
        <v-card>
            <div v-if="loading">
                <progress-bar></progress-bar>
            </div>

            <CRUDTable
                v-else
                title="Tenants"
                :headers="headers"
                :items="items"
                itemKey="id"
                :userRole="userRole"
                @delete="deleteItem"
                @edit="editItem"
                @add="addItem"
            >
                <template v-slot:editDialog="{ item }">
                    <TenantEditForm :value.sync="item" />
                </template>
            </CRUDTable>
        </v-card>
    </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import CRUDTable from "@/components/common/CRUDTable";
import TenantEditForm from "@/components/tenants/TenantEditForm";

const { mapActions, mapGetters } = createNamespacedHelpers("tenants");

export default {
    name: "TenantList",
    props: {
        userRole: String,
    },
    components: { TenantEditForm, CRUDTable },
    data() {
        return {
            isMessage: false,
            messageText: '',
            msgType: 'warning',
            loading: true,
            headers: [
                {
                    text: "Name",
                    value: "name",
                },
                {
                    text: "Tenant Id",
                    value: "tenantId",
                },
                {
                    text: "Encript",
                    value: "useEncryption",
                },
                {
                    text: "Actions",
                    value: "action",
                    sortable: false,
                },
            ],
        };
    },
    computed: {
        ...mapGetters({ items: "tenantList" }),
    },
    methods: {
        ...mapActions({
            getList: "READ_LIST_tenant",
            addTenant: "CREATE_tenant",
            // deleteTenant: "DELETE_tenant",
            updateTenant: "UPDATE_tenant",
        }),
        addItem(item) {
            //console.log(item);
            this.addTenant(item).then(
                (value) => { 
                    if (value?.response?.request?.status == 500) {
                        this.showMessage('error', JSON.parse(value.response.request.responseText).message);
                    } 
                    else {
                        this.showMessage('success', `Tenant created: ${value.name} ${value.tenantId}`);
                    } 
                }
            );
        },
        editItem(item) {
            this.updateTenant(item);
        },
        deleteItem(item) {
            console.log(item);
            // if (confirm(`Delete user ${item.username}?`)) {
            //   this.deleteUser(item);
            // }
        },
        showMessage(msgType, msgText) {
            this.isMessage = true;
            this.messageText = msgText;
            this.msgType = msgType;
            setTimeout(this.hideMessage, 5000);
        },
        hideMessage(){
            this.isMessage = false;
            this.messageText = '';
            this.msgType = '';
        }
    },
    created() {
            this.getList().finally(() => {
            this.loading = false;
        });
    },
};
</script>
