<template>
  <v-container fluid>
    <h3>Querylogs dashboard</h3>
    <progress-bar v-if="isLoading"></progress-bar>
    <WorkerQuerylogsManagerWrapper
      :baseHeaders="baseHeaders"
      :amlHeaders="amlHeaders"
      @submit="submitQuerylogs"
    />
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { createSubmitQuerylogsRequest } from "@/helpers/supervision";
import {
  QUERYLOGS_WORKER_FETCH_REQUEST,
  QUERYLOG_UPDATE_REQUEST,
} from "@/store/actions/supervision";

import WorkerQuerylogsManagerWrapper from "@/components/supervision/querylogs/WorkerQuerylogsManagerWrapper";


export default {
  components: {
    WorkerQuerylogsManagerWrapper,
  },
  data() {
    return {
      isLoading: false,
      baseHeaders: [
        {
          text: "ExtRef",
          value: "extRef",
          sortable: false
        },
        {
          text: "Searched name",
          value: "searchedName",
          width: '20vw'
        },
        {
          text: "Query date",
          value: "time",
        },
        {
          text: "Status",
          value: "status",
        },
        {
          text: 'Amount',
          value: "paymentAmount",
          sortable: false
        },
        {
          text: "Type",
          value: "type",
        },
      ],
      amlHeaders: [
        {
          text: "Query date",
          value: "time",
        },
        {
          text: "Searched name",
          value: "searchedName",
          width: '20vw'
        },
        {
          text: "Last update",
          value: "updatedAt",
        },
        {
          text: "Status",
          value: "status",
        },
        {
          text: "Analyst status",
          value: "finalStatus",
        },
        
      ],
      querylogsOpened: [],
      querylogsReopened: [],
      querylogsPending: [],
      querylogsSubmitted: [],
    };
  },
  computed: {
    ...mapGetters({
      loggedinUserId: "userId",
    }),
  },
  methods: {
    ...mapActions({
      //getquerylogsOpened: `supervision/${QUERYLOGS_WORKER_FETCH_REQUEST}`,
      getquerylogsReopened: `supervision/${QUERYLOGS_WORKER_FETCH_REQUEST}`,
      getquerylogsPending: `supervision/${QUERYLOGS_WORKER_FETCH_REQUEST}`,
      // getquerylogsSubmitted: `supervision/${QUERYLOGS_WORKER_FETCH_REQUEST}`,
      updateQuerylog: `supervision/${QUERYLOG_UPDATE_REQUEST}`,
    }),

    async submitQuerylogs(selectedQuerylogs, selectedReason, files) {
      const request = createSubmitQuerylogsRequest(selectedQuerylogs, selectedReason, files);
      await this.updateQuerylog(request);
      await this.loadData();
    },
  },
};
</script>
