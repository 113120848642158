<template>
  <v-container fluid>
    <h3>Querylogs dashboard</h3>
    <progress-bar v-if="isLoading"></progress-bar>
    <SupervisorQuerylogsManagerWrapper
      v-else
      :baseHeaders="baseHeaders"
      :amlHeaders="amlHeaders"
      :workers="userWorkers"
      @assign="assignQuerylogs"
      @submit="submitWithStatusQuerylogs"
      @submitWithStatus="submitWithStatusQuerylogs"     
      @approve="approveQuerylogs"
      @reopen="reopenQuerylogs"
      @delete="removeQuerylogs"
    />
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import supervisionClient from "@/services/supervisionClient";


import {
  createAssignQuerylogsRequest,
  createApproveQuerylogsRequest,
  createReopenQuerylogsRequest,
  createDeleteQuerylogsRequest,
  createSubmitQuerylogsRequest,
  createSubmitWithStatusQuerylogsRequest
} from "@/helpers/supervision";

import {
  QUERYLOG_UPDATE_REQUEST,
} from "@/store/actions/supervision";


import SupervisorQuerylogsManagerWrapper from "@/components/supervision/querylogs/SupervisorQuerylogsManagerWrapper";
import { Labels } from "@/helpers/dataLabels.js"

export default {
  components: {
    SupervisorQuerylogsManagerWrapper,
  },
  data() {
    return {
      isLoading: false,
      baseHeaders: [
        {
          text: "ExtRef",
          value: "extRef",
          sortable: false
        },
        {
          text: "Searched name",
          value: "searchedName",
          width: '20vw'
        },
        {
          text: "Status",
          value: "status",
        },
        {
          text: 'Amount',
          value: "paymentAmount",
          sortable: false
        },
        {
          text: "Query date",
          value: "time",
        },
        {
          text: "Type",
          value: "type",
        },
      ],
      amlHeaders: [
        {
          text: "Query date",
          value: "time",
        },
        {
          text: "Searched name",
          value: "searchedName",
          width: '20vw'
        },
    
        {
          text: "Last update",
          value: "updatedAt",
        },
        {
          text: "Status",
          value: "status",
        },
        {
          text: "Analyst status",
          value: "finalStatus",
        },
        
      ],
      userWorkers: [],
      filterParams: {},
      querylogsOpened: [],
      querylogsReopened: [],
      querylogsPending: [],
      querylogsSubmitted: [],
    };
  },
  methods: {
    ...mapActions({
      getWorkers: "supervision/READ_LIST_worker",
      updateQuerylog: `supervision/${QUERYLOG_UPDATE_REQUEST}`,
    }),
    async loadDataQuerylogs(finalStatus) {
    
      const queryLogsResponse = await supervisionClient.getUserQuerylogs(this.user.userId ,finalStatus)

      switch (finalStatus) {
        case Labels.OPEN:
          this.querylogsOpened = queryLogsResponse.data
          break;
        case Labels.REOPENED:
          this.querylogsReopened = queryLogsResponse.data
          break;
        case Labels.PENDING:
          this.querylogsPending = queryLogsResponse.data
          break;
        case Labels.SUBMITTED:
          this.querylogsSubmitted = queryLogsResponse.data
          break;
}
      this.isLoading = false;
    },
    async assignQuerylogs(selectedQuerylogs, selectedWorkerId) {
      if (this.areQuerylogsAlreadyAssignedToWorker(selectedQuerylogs, selectedWorkerId)) {
        return;
      }
      const request = createAssignQuerylogsRequest(selectedQuerylogs, selectedWorkerId);
      await this.updateQuerylog(request);

      await this.loadData();
    },
    async loadData() {
      const finalStatuses = [Labels.OPEN , Labels.REOPENED , Labels.PENDING , Labels.SUBMITTED ]
      for (const finalStatus of finalStatuses) { 
        this.loadDataQuerylogs(finalStatus)
      }
    },
    areQuerylogsAlreadyAssignedToWorker(querylogs, workerId) {
      return querylogs.every((querylog) => querylog.assignedWorker?.id === workerId);
    },

    async submitQuerylogs(selectedQuerylogs, selectedReason, files) {
      const request = createSubmitQuerylogsRequest(selectedQuerylogs, selectedReason, files);
      await this.updateQuerylog(request);
      await this.loadData();
    },

    async submitWithStatusQuerylogs(selectedQuerylogs, selectedReason, files, status) {
      const request = createSubmitWithStatusQuerylogsRequest(selectedQuerylogs, selectedReason, files, status);
      console.log('requestrequestrequest', request);
      await this.updateQuerylog(request);
      await this.loadData();
    },

    async approveQuerylogs(selectedQuerylogs, message, files) {
      const request = createApproveQuerylogsRequest(selectedQuerylogs, message, files);
      await this.updateQuerylog(request, message);
      await this.loadData();
    },

    async reopenQuerylogs(selectedQuerylogs, message, files) {
      const request = createReopenQuerylogsRequest(selectedQuerylogs, message, files);
      await this.updateQuerylog(request, message);
      await this.loadData();
    },

    async removeQuerylogs(selectedQuerylogs) {
      const request = createDeleteQuerylogsRequest(selectedQuerylogs);
      await this.deleteQuerylog(request);
      await this.loadData();
    },
    async getUserWorkers(managerId) {
      const workers = await supervisionClient.getWorkers({managerId})
      return workers.data.filter((worker) => {
        return worker.managerId === managerId;
      });
    },
  },
  async created() {
    this.isLoading = true;

    this.user = {
      userRole: this.$store.getters.userRole, 
      tenantId: this.$store.getters.tenantId, 
      userId: this.$store.getters.userId
    };
    this.userWorkers = await this.getUserWorkers(this.user.userId)

    this.loadData();
  }
};
</script>
