<template>
  <v-container fluid v-if="querylogsToShow && !persistanceDashboard">
    <v-row class="mt-2 mb-5">
      <v-text-field
        label="Status"
        :value="filterTextsDisplay.status"
        dense
        readonly
        disabled
        class="mx-3"
      />
      <v-text-field
        label="Assigned Worker(s)"
        :value="filterTextsDisplay.workers"
        dense
        readonly
        disabled
        class="mx-3"
      />
      <v-text-field
        label="Time frame"
        :value="filterTextsDisplay.timeframes"
        dense
        readonly
        disabled
        class="mx-3"
      />
    </v-row>
    <v-row v-if="!isLoading">
      <v-col>
        <v-data-table
          id="table"
          :headers="headers"
          :items="querylogsToShow"
          item-key="id"
          @click:row="displayQuerylogDetails"
          class="elevation-2"
        >
        <!-- Assigned Analyst -->

        <template #item.workerName[0].username="{ value }">
            <span> {{ value.includes('search@') ? importedAutoClosed : value}} </span>
          </template>

          <template #item.finalStatus="{ value }">
            <span> {{ value === 'submitted' ? 'waiting for approval' : value}} </span>
          </template>

          <template #item.time="{ value }">
            <span> {{ formatTime(value) }} </span>
          </template>

          <template #item.updatedAt="{ value }">
            <span> {{ formatTime(value) }} </span>
          </template>

        </v-data-table>
      </v-col>
    </v-row>
    <progress-bar v-else style="width: 93%;"></progress-bar>

  </v-container>
  <PersistanceReportTable v-else-if="persistanceDashboard"/>

  <v-container v-else> No Data to show </v-container>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import { QUERY_LOG_SAVE_TABLE } from "@/store/actions/queryLog";
import { format } from "@/utils/dates";
import supervisionClient from "@/services/supervisionClient";
import PersistanceReportTable from '../PersistanceReportTable.vue';

import { Labels, labelsList, autoClosed } from "@/helpers/dataLabels.js"
import shared from '@/utils/shared';


const { mapGetters, mapActions } = createNamespacedHelpers("queryLog");

export default {
  components: {
    PersistanceReportTable,
  },
  props: {
    filterBy: {
      type: String,
    },
  },
  data() {
    return {
      querylogsToShow: [],
      previousTable: [],
      persistanceDashboard: false,
      isLoading: true,
      importedAutoClosed: autoClosed,
      headers: [
        {
          text: "ExtRef",
          value: "extRef",
          sortable: false
        },
        {
          text: "Query date",
          value: "time",
        },
        {
          text: "status",
          value: "status",
        },
        {
          text: "Searched name",
          value: "searchedName",
          width: "20vw"
        },
        {
          text: "Assigned Analyst",
          value: "workerName[0].username",
        },
        {
          text: 'Amount',
          value: "paymentAmount",
          sortable: false
        },
        {
          text: "Last update",
          value: "updatedAt",
        },
        {
          text: "Type",
          value: "type",
        },
      ],
      time_range: localStorage.getItem('timeFrameInMinutes')
    };
  },
  computed: {
    ...mapGetters({
      reportDashboardQueries: "reportDashboardQueries",
    }),
    filterTextsDisplay() {
      const statusSet = new Set();
      const workersSet = new Set();
      const timeframesSet = new Set();

      this.querylogsToShow.forEach((querylog) => {
        statusSet.add(querylog?.status);
        workersSet.add(querylog.workerName[0]?.username || "");
        

        if (!querylog.timeframe) {
          timeframesSet.add("All");
        } else if (querylog.timeframe.newer) {
          timeframesSet.add(`Less than ${shared.timeframeString(this.time_range)}`);
        } else if (querylog.timeframe.older) {
          timeframesSet.add(`More than ${shared.timeframeString(this.time_range)}`);
        }
      });

      return {
        status: Array.from(statusSet.values()).join(", "),
        workers: Array.from(workersSet.values()).join(", "),
        timeframes: Array.from(timeframesSet.values()).join(", "),
      };
    },
  },
  methods: {
    ...mapActions({
      saveTable: QUERY_LOG_SAVE_TABLE,
    }),
    async displayQuerylogDetails(item) {
      await this.saveTable(this.querylogs);

      this.$router.push({
        name: "queryLogDetails",
        params: { id: item._id },
      });
    },
    formatTime(time) {
      return format(time);
    },
   
    getPreviouseQuieries() {
      this.previousTable = this.currTable;

      if (!this.previousTable.length) {
        this.$router.push("/");
      }
    },
    async getQueryLogs(datalabel) {
      const getAllQueries =
        !datalabel || datalabel === "all";
      let finalStatuses = getAllQueries
        ? [...labelsList , "timeframe"]
        : [datalabel];


      if (datalabel) {
        for (const query of finalStatuses) {
          
            const response =
              await supervisionClient.getStatisticForChiefOrSupervisor({
                user: localStorage.getItem("user-id"),
                status: query,
                tenantId: localStorage.getItem("tenantId"),
              });

            let filteredList = datalabel !== 'timeframe' && this.filterBy 
              ? response.data.filter(
                  (query) => query.workerName[0]?.username === this.filterBy
                )
              : response.data;

            if (datalabel !== 'timeframe' ) {
              filteredList = filteredList.filter((queryLog) => queryLog.finalStatus ==this.$route.query.state )
            }

            this.querylogsToShow.push(...filteredList);
        }
      } else {
          
          const response =
            await supervisionClient.getStatisticForChiefOrSupervisor({
              user: localStorage.getItem("user-id"),
              status: "open",
              tenantId: localStorage.getItem("tenantId"),
            });

          this.querylogsToShow.push(...response.data);
        
      }
    },
  },

  async created() {
    this.isLoading = true
    const datalabel = this.$route.query.state;
    if (datalabel === 'persistence') {
      return this.persistanceDashboard = true
    }
    this.reportDashboardQueries.length
      ? this.querylogsToShow = this.reportDashboardQueries
      : await this.getQueryLogs(datalabel);

    for (const item of this.querylogsToShow) {
       
        if (item.incomingTransactionFullData) {
          const parsedData = shared.getMidAndExtRef(item)
          item.extRef = parsedData.externalIdRef[0] 
          item.paymentAmount = shared.parseTransactionAmount(item.incomingTransactionFullData)
        } 
    }

    if (this.$route.query.state === Labels.OPEN || !this.$route.query.state) {
      this.headers = this.headers.filter(header => header.text !== "Assigned Analyst")
    }
    this.isLoading = false

  },
};
</script>

<style scoped>
#table:hover {
  cursor: pointer;
}
</style>
