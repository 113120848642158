<template>
  <v-container>
    <v-row>
      <v-col cols="1">Read:</v-col>
      <v-col cols="2">Successfully: {{value.readSuccessfully}}</v-col>
      <v-col cols="2">Failed: {{value.readUnsuccessfully}}</v-col>
    </v-row>
    <v-row>
      <v-col cols="1">Parsed:</v-col>
      <v-col cols="2">Successfully: {{value.parsedSuccessfully}}</v-col>
      <v-col cols="2">Failed: {{value.parsedUnsuccessfully}}</v-col>
      <v-col cols="2">Skipped: {{value.parsedSkipped}}</v-col>
      <v-col cols="2">In total: {{value.parsedInTotal}}</v-col>
    </v-row>
    <v-row>
      <v-col cols="1">Formatted:</v-col>
      <v-col cols="2">Successfully: {{value.formattedSuccessfully}}</v-col>
      <v-col cols="4">Failed: {{value.formattedUnsuccessfully}}</v-col>
      <v-col cols="2">In total: {{value.formattedInTotal}}</v-col>
    </v-row>
  </v-container>

</template>

<script>
export default {
  name: 'DataSource',
  props: {
    value: Object,
  },
};
</script>

<style scoped>

</style>
