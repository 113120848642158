<template>
  <WorkerReportManagerWrapper
    :workerId="loggedinUserId"
  />
</template>

<script>
import { mapGetters } from "vuex";
import WorkerReportManagerWrapper from "@/components/supervision/reports/WorkerReportManagerWrapper";

export default {
  components: {
    WorkerReportManagerWrapper,
  },
  computed: {
    ...mapGetters({
      loggedinUserId: "userId",
    }),
  },
};
</script>
