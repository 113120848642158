<template>
    <v-card>
        <CRUDTable
            :title="title"
            :headers="headers"
            :items="workers"
            :userRole="userRole"
            sort-by="username"
            item-key="id"
            @setWorkload="setWorkload($event)"
        >
        </CRUDTable>
    </v-card>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import CRUDTable from "@/components/common/CRUDTable";
const { mapActions } = createNamespacedHelpers("users");

export default {
    props: {
        userRole: String,
        supervisors: Array,
        workers: Array,
        title: String,
    },
    components: { 
        CRUDTable
    },
    data() {
        return {
            headers: [
                {
                    text: "User",
                    value: "username",
                },
                {
                    text: "First name",
                    value: "firstname",
                },
                {
                    text: "Last name",
                    value: "lastname",
                },   
                {
                    text: "Supervisor",
                    value: "managerName",
                },     
                {
                    text: "Workload",
                    value: "userOptions",
                }
            ],
        };
    },
    methods: {
        ...mapActions({
            updateUser: "UPDATE_user",
        }),
        emitEvent(type, item) {
            this.$emit("modify", type, item);
        },
        setWorkload(itemObj) {
            const message = `Set ${itemObj.item.username} as ${itemObj.event ? 'active' : 'passive'}`;
            if (confirm(message)) {
                if (Object.prototype.hasOwnProperty.call(itemObj.item, 'userOptions')) {
                    itemObj.item.userOptions.workload =  itemObj.event;
                } 
                else {
                    itemObj.item.userOptions = {
                        workload: itemObj.event
                    }
                }
                this.updateUser(itemObj.item);
            }
        }
    }
};
</script>
