<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="pa-0">
        <v-text-field
          label="Tenant Name"
          id="name"
          name="name"
          type="text"
          v-model.trim="value.name"
          :rules="[rules.required, rules.minLen(2)]"
          prepend-icon="picture_in_picture"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="pa-0">
        <v-text-field
          :label="tenantIdLable"
          id="tenantId"
          name="tenantId"
          type="text"
          v-model.trim="value.tenantId"
          :rules="[rules.required, rules.minLen(2)]"
          prepend-icon="assignment_ind"
          :readonly="tenantReadOnly"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col class="pa-0">
        <v-checkbox
          label="Use Encription"
          id="useEncryption"
          name="useEncryption"
          v-model.trim="value.useEncryption"
          prepend-icon="translate"
        ></v-checkbox>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import { required, minLen } from "@/utils/validations";

const { mapActions } = createNamespacedHelpers("tenants");

export default {
  props: {
    value: Object,
  },
  data() {
    return {
      rules: {
        required,
        minLen,
      },
      tenantsList: [],
      tenantReadOnly: false,
      tenantIdLable: "Tenant Id",
      tenantIdWarningMsg: "(cannot modify)",
    };
  },
  methods: {
    ...mapActions({
      fetchTenants: "READ_LIST_tenant",
    }),
  },
  async created() {
    this.tenantsList = await this.fetchTenants();

    if (this.value["tenantId"]) {
      this.tenantReadOnly = true;
      this.tenantIdLable += ` ${this.tenantIdWarningMsg}`;
    }
  },
};
</script>

<style scoped>
.row {
  margin-bottom: 15px;
}
</style>
