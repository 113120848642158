<template>
  <v-form @submit.prevent="submit">
    <v-card>
      <v-card-title> Choose a analyst </v-card-title>
      <v-card-subtitle>
        Warning: Choosing an empty option will mark the querylog(s) as open
      </v-card-subtitle>
      <v-card-text>
        <v-select
          label="Analysts..."
          :items="workersDropdownItems"
          v-model="selectedWorkerName"
          item-text="username"
          item-value="id"
          single-line
          clearable
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="secondary" text @click="close"> Cancel </v-btn>
        <v-btn color="primary" type="submit"> Submit </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import { sortBy } from "lodash";

export default {
  props: {
    data: {
      type: Object,
      default: () => ({
        selectedQuerylogs: [],
        workers: [],
      }),
    },
  },
  data() {
    return {
      selectedWorkerId: null,
      selectedWorkerName: ''
    };
  },
  created() {
    // console.log("this.data :>> ", this.data);
  },
  computed: {
    workersDropdownItems() {
      return sortBy(this.data.workers, ["username"]);
    },
  },
  watch: {
    selectedWorkerName(newValue) {
      this.selectedWorkerName = newValue
      const selectedWorker = this.data.workers.filter(worker => worker.username === this.selectedWorkerName)
      this.selectedWorkerId = selectedWorker[0]._id
    }
  },
  methods: {
    submit() {
      const selectedWorker = this.data.workers.filter(worker => worker.username === this.selectedWorkerName)
      this.selectedWorkerId = selectedWorker[0]._id
      this.$emit("submit", [this.data.selectedQuerylogs, this.selectedWorkerId]);
      this.selectedWorkerId = null;
    },
    close() {
      this.$emit("close");
      this.selectedWorkerId = null;
    },
  },
};
</script>
