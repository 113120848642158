<template>
  <v-form @submit.prevent="submit">
    <v-card>
      <v-card-title> Close the selected querylogs? </v-card-title>
      <v-card-subtitle>
        Note: can only close querylogs with similar submitted status
      </v-card-subtitle>
      <v-card-text>
        <v-select
          label="Status"
          :items="statuses"
          v-model="selectedStatus"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="secondary" text @click="close"> Cancel </v-btn>
        <v-btn :disabled="!areSelectedQuerylogsHaveSimilarStatus()" color="primary" type="submit"> Submit </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => ({
        selectedQuerylogs: [],
      }),
    },
  },
  data() {
    return {
      statuses: ['verified', 'flagged'],
      selectedStatus: '',
    };
  },
   methods: {
    areSelectedQuerylogsHaveSimilarStatus() {
      if (this.data.selectedQuerylogs.length === 0) {
        return true;
      }

      const status = this.data.selectedQuerylogs[0].workerSubmissionStatus;

      return this.data.selectedQuerylogs.every((querylog) => {
        const { workerSubmissionStatus } = querylog;

        return workerSubmissionStatus ? workerSubmissionStatus === status : true;
      });
    },
    submit() {
      this.$emit('submit', [this.data.selectedQuerylogs, this.selectedStatus]);
      this.close();
    },
    close() {
      this.$emit('close');
      this.selectedStatus = this.data.selectedQuerylogs[0].workerSubmissionStatus;
    },
  },
  created() {
    if (this.areSelectedQuerylogsHaveSimilarStatus()) {
      this.selectedStatus = this.data.selectedQuerylogs[0].workerSubmissionStatus;
    } else {
      this.selectedStatus = '';
    }
  },
};
</script>
