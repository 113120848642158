<template>
    <v-data-table
        :headers="headers"
        :items="items"
        :sort-by="sortBy"
        :item-key="itemKey"
        class="elevation-1"
    >
        <template
            v-for="customHeader in customHeaders"
            v-slot:[`item.${customHeader}`]="{ item, header, value }"
            >
            <slot :name="[`item.${customHeader}`]" :slotProps="{ item, header, value }" />
        </template>

        <template v-slot:top>
            <v-toolbar flat color="white">
                <v-toolbar-title>{{ title }} </v-toolbar-title>
                <v-divider class="mx-4" inset vertical />
                <v-spacer />
                <v-dialog v-model="dialog" max-width="500px" persistent v-if="userRole === 'SYS_ADMIN' || userRole === 'ADMIN' ">
                    <template v-slot:activator="{ on }">
                        <v-btn color="primary" dark class="mb-2" v-on="on"><v-icon size="medium" class="mr-2">person_add</v-icon> New</v-btn>
                    </template>

                    <v-form ref="userForm" @submit.prevent="save">
                        <v-card>
                            <v-card-title class="primary white_title">
                                <span class="headline"><v-icon color="#fff" class="mr-2 pb-1">person_add</v-icon>{{ formTitle }}</span>
                            </v-card-title>

                            <v-card-text :key="dialog">
                                <slot name="editDialog" :item="editedItem">
                                    <!--                 fall back logic with autogenerated edit fields-->
                                    <v-container>
                                        <v-row>
                                            <v-col cols="12" sm="6" md="4" v-for="(field, index) in headers" :key="index">
                                                <v-text-field
                                                    v-if="field.value !== 'action' && field.value !== 'id'"
                                                    v-model="editedItem[field.value]"
                                                    :label="field"
                                                />
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </slot>
                            </v-card-text>

                            <v-card-actions>
                                <v-spacer />
                                <v-btn color="secondary" text @click="close">Cancel</v-btn>
                                <v-btn color="primary" type="submit">Save</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-form>
                </v-dialog>
            </v-toolbar>
        </template>

        <template #item.userOptions="{ item }"><!-- :label="(workload(item))?'active':'passive'" -->
            <v-checkbox
                color="success"
                id="workload"
                name="workload"
                :input-value="(workload(item))? true : false"
                @change="$emit('setWorkload', {event: $event, item})"
                ></v-checkbox>
        </template>

        <template v-slot:item.action="{ item }">
            <v-icon v-if="!isUserChiefSupervisor" small class="mr-2" @click="editItem(item)"> edit </v-icon>
            <v-icon v-if="!isUserChiefSupervisor" small @click="deleteItem(item)"> delete </v-icon>
            <v-icon v-if="isSupervisors" small class="ml-2" @click="expandItem(item)">mdi-account-group-outline</v-icon>
        </template>
    </v-data-table>
</template>

<script>
import { findIndex } from "lodash";

export default {
    props: {
        itemKey: String,
        title: String,
        headers: Array,
        userRole: String,
        items: Array,
        tenants: Array,
        sortBy: String,
        customHeaders: Array,
        isSupervisors: Boolean,
    },
    data: () => ({
        dialog: false,
        editedIndex: -1,
        editedItem: {},
        defaultItem: {},
        isReset: false,
    }),

    computed: {
        formTitle() {
        return this.editedIndex === -1 ? "Add" : "Edit";
        },
        isUserChiefSupervisor() {
        return this.userRole === "CHIEF_SUPERVISOR";
        },
    },

    methods: {
        editItem(item) {
            this.editedIndex = findIndex(this.items, { [this.itemKey]: item[this.itemKey] });
            this.editedItem = { ...item };
            this.dialog = true;
        },
        deleteItem(item) {
            this.$emit("delete", item);
        },
        expandItem(item) {
            this.$emit("expand", item);
        },
        close() {
            this.dialog = false;
            this.editedItem = {};
            this.editedIndex = -1;
            this.$emit("close");
        },
        save() {
            if (this.$refs.userForm.validate()) {
                if (this.editedIndex > -1) {
                    this.$emit("edit", this.editedItem);
                } 
                else {
                    this.$emit("add", this.editedItem);
                }
                this.close();
            }
        },
        workload(item) {
            if (item['userOptions'] && item.userOptions['workload'] ) {
                return true;
            } else {
                return false;
            }
        }
    },
};
</script>

<style scoped>
    .primary.white_title {
        color: #fff !important;
    }
</style>