<template>
    <v-dialog transition="dialog-bottom-transition" max-width="600">
        <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" class="mb-2" v-bind="attrs" v-on="on">
                <v-icon class="mr-2">file_copy</v-icon> Daily report
            </v-btn>
        </template>

        <template v-slot:default="dialog">
            <v-card>
                <v-toolbar color="primary" dark>Daily Report</v-toolbar>
                <v-card-text>
                    <v-row class="d-flex flex-column align-center ma-5">
                        <v-date-picker elevation="15" v-model="picker" :show-current="currentDate" class="mb-5"
                        :max="currentDate"></v-date-picker>
                        <v-progress-circular v-if="isGenerating" :size="50" color="primary"
                            indeterminate></v-progress-circular>
                    </v-row>
                </v-card-text>
                <v-alert class="mx-15" v-if="error" color="error" dismissible @input="closeAlert"> {{ error }} </v-alert>
                <v-card-actions class="justify-end">
                    <v-btn color="primary" @click="generateReport">Generate</v-btn>
                    <v-btn text @click="dialog.value = false">Close</v-btn>
                </v-card-actions>
            </v-card>
        </template>
    </v-dialog>
</template>

<script>
import moment from 'moment';
import * as momentTz from 'moment-timezone';
import shared from '@/utils/shared'


export default {
    data() {
        return {
            isGenerating: false,
            currentDate: moment().format('YYYY-MM-DD'),
            picker: moment().subtract(moment().utcOffset(), 'minutes').format('YYYY-MM-DD'),
            error: false,
        }
    },
    methods: {
        async generateReport() {
            this.isGenerating = true

            try {
                const currentTimezone = localStorage.getItem('timezone')
                const dateStartTimeStamp = momentTz.tz(this.picker, currentTimezone).valueOf()
                const response = await this.$apiClient.getDailyReport(dateStartTimeStamp)

                if (response.data.length) {
                    const csvContent = shared.generateCSVContent(response.data);
                    shared.downloadCSV(`${this.picker}-report.csv`, csvContent);
                } else {
                    this.error = 'No data on this date'
                }

                this.isGenerating = false
            } catch (e) {
                this.isGenerating = false
                this.error = e
            }
        },
        closeAlert() {
            this.error = false
        }
    },

}

</script>
