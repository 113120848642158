<template>
  <v-container fluid>
    <h3 class="page-title">Tenants Management</h3>
    <v-row>
      <v-col>
        <tenant-list :userRole="userRole" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
//import store from "@/store";
import TenantList from "@/components/tenants/TenantList";

export default {
  name: "TenantManagement",
  data() {
    return {
      userRole: this.$store.getters.userRole,
    };
  },
  components: {
    TenantList,
  },
};
</script>
