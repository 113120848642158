<template>
  <v-container>
    <v-row class="mb-6 flex-wrap">
      <v-col v-for="chart in chartsToDisplay" :key="chart" cols="3">
        <DoughnutChart
          :chart-data="chartsData[chart]"
          :title="capitalize(chart)"
          @click="displayQuerylogsData"
          :legend="getShowLegendValue(chart)"
        />
      </v-col>
    </v-row>
    <v-row>
        <slot name="external-legend" />
    </v-row>
  </v-container>
</template>

<script>
import { pick } from "lodash";
import { mapActions } from "vuex";
import { REPORT_DASHBOARD_STATISTICS_DATA } from "@/store/actions/queryLog";
import DoughnutChart from "@/components/supervision/reports/chart/DoughnutChart";
import shared from "@/utils/shared";

export default {
  components: { DoughnutChart },
  props: {
    groupedQuerylogs: Object,
    chartColors: Object,
    legends: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      chartsData: {
        open: null,
        reopened: null,
        pending: null,
        submitted: null,
        closed: null,
        timeframe: null,
      },
    };
  },
  computed: {
    chartsToDisplay() {
      return Object.keys(this.chartsData).filter((type) => {
        if (
          this.chartsData[type]?.datasets[0]?.data[0] ||
          this.chartsData[type]?.datasets[0].data[1]
        ) {
          return this.chartsData[type];
        } else if (type === "timeframe") {
          return this.chartsData[type];
        }
      });
    },
  },
  methods: {
    ...mapActions({
      setQueriesForReportsTable: `queryLog/${REPORT_DASHBOARD_STATISTICS_DATA}`
    }),
    capitalize(str) {
      return shared.capitalize(str);
    },
    createChartsData() {
      Object.keys(this.groupedQuerylogs).forEach((datalabel) => {
        const chartData = this.buildChartData(this.groupedQuerylogs[datalabel], datalabel);

        this.chartsData[datalabel] = chartData;
      });
    },
    buildChartData(querylogsMap, datalabel) {
      if (!querylogsMap) return null

      const keys = Object.keys(querylogsMap);

      if (keys.length === 0) return null;

      return {
        labels: keys,
        datasets: [
          {
            label: datalabel,
            backgroundColor: this.getColors(keys, datalabel),
            data: keys.map((key) => querylogsMap[key]?.length || 0),
          },
        ],
      };
    },
    getColors(keys, datalabel) {
      const colorsObj = pick(this.chartColors[datalabel], keys);
      return Object.values(colorsObj);
    },
    getShowLegendValue(datalabel) {
      return this.legends.includes(datalabel);
    },
    displayQuerylogsData(clickInfo) {
      const { index, datalabel } = clickInfo;
      if (datalabel === 'persistence') {
        this.$router.push({
          name: "supervisionReportTable",
          query: { state: datalabel },
        });
      }

      let workerName = ""

      const querylogsByDataLabel = this.groupedQuerylogs[datalabel];
      const selectedQuerylogs = Object.values(querylogsByDataLabel)[index];
      workerName = selectedQuerylogs[0].workerName[0].username
      this.setQueriesForReportsTable(selectedQuerylogs)

      this.$router.push({
        name: "supervisionReportTable",
        query: { state: datalabel ? datalabel : "all" },
        params: {filterBy: workerName}
      });
    },
  },
  created() {
    this.createChartsData();
  },
};
</script>
