<template> 
  <v-container fluid v-if="querylogsToShow.length">
    <v-text-field label="Status" value="Persistence" dense readonly disabled class="mx-3" />
    <v-data-table
      id="table"
      item-key="_id"
      :loading="loading"
      :headers="headers"
      @update:options="loadData"
      :items="querylogsToShow"
      :options.sync="options"
      :server-items-length="totalItems"
      :items-per-page="itemsPerPage"
      class="elevation-1"
      :footer-props="{
        'items-per-page-options': itemsPerPageList,
        'items-per-page-text': 'Items per page',
        'show-current-page': true,
        'show-first-last-page': true,
        'hide-default-footer': true,
      }"
      :server-data="loadData"
    >

      <template #item.finalStatus="{ value }">
        <span> {{ value === 'submitted' ? 'waiting for approval' : value}} </span>
      </template>

      <template #item.time="{ value }">
        <span> {{ formatTime(value) }} </span>
      </template>

      <template #item.updatedAt="{ value }">
        <span> {{ formatTime(value) }} </span>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import shared from '@/utils/shared'

import apiClient from "@/services/apiClient";
import { format } from "@/utils/dates";

export default {
  data() {
    return {
      totalItems: 0,
      loading: true,
      querylogsToShow: [],
      skipNumber: 0,
      itemsPerPage: 10,
      itemsPerPageList: [10, 25, 50, 100],
      skip: 0,
      options: {
        page: 1,
        pageStart: 1,
        sortBy: ["time"],
        sortDesc: [true],
      },
      headers: [
        {
          text: "ExtRef",
          value: "extRef",
          sortable: false
        },
        {
          text: "Query date",
          value: "time",
        },
        {
          text: "Searched name",
          value: "searchedName",
          width: "20vw",
        },
        {
          text: "Transaction status",
          value: "finalStatus",
        },
        {
          text: 'Amount',
          value: "paymentAmount",
          sortable: false
        },
        {
          text: "Last update",
          value: "updatedAt",
        },
        {
          text: "Type",
          value: "type",
        },
      ],
    };
  },
  methods: {
    async loadData({ page, sortDesc, itemsPerPage, sortBy }) {
      this.loading = true;
      let { skip } = this;
      skip = (page - 1) ;

      // When changing this boolean parameters, make sure to change in ChiefSupervisorReportManagerWrapper as well
      const response = await apiClient.getQueryLog({
        perPage: itemsPerPage,
        skipNumber: skip ,
        showAutoVerified: true,
        hideEmptyResults: false,
        sortBy: sortBy[0],
        sortDesc: sortDesc[0] ? 1 : -1,
      });
      const data = response.data;

      if (data.length) {
        this.totalItems = data[0].metadata.total;
        this.querylogsToShow = data[0].data;
      }

      for (const item of this.querylogsToShow) {
        if (item.incomingTransactionFullData) {
          const parsedData = shared.getMidAndExtRef(item)
          item.extRef = parsedData.externalIdRef[0]
          item.paymentAmount = shared.parseTransactionAmount(item.incomingTransactionFullData)
        } 
    }

      this.loading = false;
    },
    formatTime(time) {
      return format(time);
    },
  },

  async created() {
    const response = await apiClient.getQueryLog();
    return (this.querylogsToShow = response.data[0].data);
  },
};
</script>
