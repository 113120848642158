var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.querylogsToShow.length)?_c('v-container',{attrs:{"fluid":""}},[_c('v-text-field',{staticClass:"mx-3",attrs:{"label":"Status","value":"Persistence","dense":"","readonly":"","disabled":""}}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"id":"table","item-key":"_id","loading":_vm.loading,"headers":_vm.headers,"items":_vm.querylogsToShow,"options":_vm.options,"server-items-length":_vm.totalItems,"items-per-page":_vm.itemsPerPage,"footer-props":{
      'items-per-page-options': _vm.itemsPerPageList,
      'items-per-page-text': 'Items per page',
      'show-current-page': true,
      'show-first-last-page': true,
      'hide-default-footer': true,
    },"server-data":_vm.loadData},on:{"update:options":[_vm.loadData,function($event){_vm.options=$event}]},scopedSlots:_vm._u([{key:"item.finalStatus",fn:function({ value }){return [_c('span',[_vm._v(" "+_vm._s(value === 'submitted' ? 'waiting for approval' : value)+" ")])]}},{key:"item.time",fn:function({ value }){return [_c('span',[_vm._v(" "+_vm._s(_vm.formatTime(value))+" ")])]}},{key:"item.updatedAt",fn:function({ value }){return [_c('span',[_vm._v(" "+_vm._s(_vm.formatTime(value))+" ")])]}}],null,false,254078098)})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }