<template>
  <v-container fluid>
    <h3 class="page-title">User Management</h3>
    <v-row>
      <v-col>
        <UserList :userRole="userRole" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import store from "@/store";
import UserList from "@/components/users/UserList";

export default {
  name: "UserManagement",
  data() { 
    return {
      userRole: store.getters.userRole,
    };
  },
  components: {
    UserList,
  },
};
</script>
