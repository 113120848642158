<template>
  <v-container class="d-flex flex-column align-center">
    <h2>Color legend:</h2>
    <div class="row ma-2">
      <div v-for="(color, colorKey) in uniqueColors" :key="colorKey" class="my-2 mx-1">
        <v-chip :color="color" style="font-size: 16px">
          {{ colorKey }}
        </v-chip>
      </div>
    </div>
  </v-container>
</template>

<script>
export default {
  props: {
    charts: Array,
    colors: Object,
    workers: Array,
  },
  computed: {
    uniqueColors() {
      const map = new Map();
      this.charts.forEach((datalabel) => {
        Object.entries(this.colors[datalabel]).forEach(([colorKey, colorValue]) => {
          map.set(colorKey, colorValue);
        });
      });
      Object.keys(this.colors.timeframe).forEach((timeframe) => {
        map.set(timeframe, this.colors.timeframe[timeframe]);
      });

      return Object.fromEntries(map.entries());
    },
  },
};
</script>

<style scoped>
.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
</style>
