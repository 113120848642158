<template>
  <v-container>
    <v-row>
      <v-text-field
        label="User Email"
        v-model.trim="value.username"
        :rules="[rules.required, rules.email]"
        prepend-icon="email"
        class="mb-2"
      />
    </v-row>
    <v-row>
      <v-text-field
        label="Password"
        v-model="normalizedPass"
        :rules="[rules.required, rules.minLen(6)]"
        prepend-icon="lock"
        class="mt-2 mb-2 pass"
      />
    </v-row>
    <v-row>
      <v-text-field
        label="First Name"
        v-model.trim="value.firstname"
        :rules="[rules.required, rules.minLen(2)]"
        prepend-icon="person"
        class="mt-2 mb-2"
      />
    </v-row>
    <v-row>
      <v-text-field
        label="Last Name"
        v-model="value.lastname"
        :rules="[rules.required, rules.minLen(2)]"
        prepend-icon="person"
        class="mt-2 mb-2"
      />
    </v-row>
    <v-row>
      <v-select
        label="Analysts"
        :items="dropdownWorkerItems"
        v-model="workersToAdd"
        :rules="[rules.maxItems(10)]"
        multiple
        chips
        deletable-chips
        item-text="username"
        item-value="id"
        no-data-text="No available analysts"
        prepend-icon="group_work"
        class="mt-2 mb-2"
      />
    </v-row>
    <v-row>
      <v-text-field label="Group" :value="groupName" prepend-icon="group" class="mt-2 mb-2" />
    </v-row>
  </v-container>
</template>

<script>
import { required, minLen, email, maxItems } from "@/utils/validations";

export default {
  props: {
    value: Object,
    workers: Array,
  },
  data() {
    return {
      checker: "******",
      workersToAdd: [],
      rules: {
        required,
        minLen,
        email,
        maxItems,
      },
    };
  },
  created() {
    this.checker = "******";
    this.value.workers = [];
    this.workersToAdd = this.supervisorOwnWorkers;
  },
  watch: {
    workersToAdd: {
      handler(newVal, oldVal) {
        oldVal;
        this.value.workers = newVal;
      },
      deep: true,
    },
  },
  computed: {
    availableWorkers() {
      console.log(
        "this.workers.filter((worker) => worker.managerId === null) :>> ",
        this.workers.filter((worker) => worker.managerId === null)
      );
      return this.workers.filter((worker) => worker.managerId === null);
    },
    supervisorOwnWorkers() {
      return this.workers.filter((worker) => worker.managerId === this.value._id);
    },
    dropdownWorkerItems() {
      return (this.workers.filter((worker) => worker.managerId === null) || []).concat(
        this.workers.filter((worker) => worker.managerId === this.value._id)
      );
    },
    groupName() {
      return this.group;
    },
    normalizedPass: {
      get() {
        return this.checker;
      },
      set(value) {
        if (value == "*****") {
          value = "";
        } else if (value.includes("******") && value.length > 6) {
          value = value.slice(6);
        }
        this.checker = value;
        this.value.password = value;
      },
    },
  },
};
</script>
<style scoped></style>
