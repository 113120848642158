<template>
  <v-container fluid>
    <h3>Querylogs dashboard</h3>
    <ChiefsupervisorQuerylogsManagerWrapper
      :baseHeaders="baseHeaders"
      :amlHeaders="amlHeaders"
      :workers="workers"
    />
  </v-container>
</template>

<script>
import ChiefsupervisorQuerylogsManagerWrapper from "@/components/supervision/querylogs/ChiefsupervisorQuerylogsManagerWrapper";

export default {
  components: {
    ChiefsupervisorQuerylogsManagerWrapper,
  },
  data() {
    return {
      user: {},
      isLoading: true,
      baseHeaders: [
        {
          text: "ExtRef",
          value: "extRef",
          sortable: false
        },
        {
          text: "Searched name",
          value: "searchedName",
          width: '20vw'
        },
        {
          text: "Status",
          value: "status",
        },
        {
          text: 'Amount',
          value: "paymentAmount",
          sortable: false
        },
        {
          text: "Query date",
          value: "time",
        },
        {
          text: "Type",
          value: "type",
        },
      ],
      amlHeaders: [
        {
          text: "Query date",
          value: "time",
        },
        {
          text: "Searched name",
          value: "searchedName",
          width: '20vw'
        },
        {
          text: "Last update",
          value: "updatedAt",
        },
        {
          text: "Status",
          value: "status",
        },
        {
          text: "Analyst status",
          value: "finalStatus",
        },
        
      ],
      filterParams: {},
    };
  },
  computed: {
    workers() {
      return this.$store.getters.workers.filter((worker) => {
        return worker.managerId === this.loggedinUserId; 
      });
    },
    allWorkers() {
      return this.$store.getters.workers;
    },
  },

};
</script>
