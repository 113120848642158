<template>
  <v-card>
    <CRUDTable
      title="Supervisors"
      :headers="headers"
      :items="supervisors"
      :userRole="userRole"
      :isSupervisors="true"
      sort-by="username"
      item-key="_id"
      :custom-headers="['workers']"

      @expand="emitEvent('expand', $event)"

    >
      <template v-slot:editDialog="{ item }">
        <SupervisorEditForm
          type="Object"
          :workers="workers"
          :groups="groups"
          :value="item"
          :group="group"
          :key="resetItem"
        />
      </template>
      <template v-slot:item.workers="{ slotProps: { item } }">
        {{
          workers.filter((worker) => {
            return worker.managerId === item.id;
          }).length
        }}
      </template>
    </CRUDTable>
  </v-card>
</template>

<script>
import CRUDTable from "@/components/common/CRUDTable";
import SupervisorEditForm from "./SupervisorEditForm";

export default {
  props: {
    userRole: String,
    supervisors: Array,
    workers: Array,
    groups: Array,
    group: String,
  },
  components: { CRUDTable, SupervisorEditForm },
  data() {
    return {
      resetItem: false,
      headers: [
        {
          text: "User",
          value: "username",
        },
        {
          text: "First name",
          value: "firstname",
        },
        {
          text: "Last name",
          value: "lastname",
        },
        {
          text: "Workers",
          value: "workers",
        },
        {
          text: "Actions",
          value: "action",
          sortable: false,
        },
      ],
    };
  },

  methods: {
    emitEvent(type, item) {
      this.$emit("modify", type, item);
    },
  },
};
</script>
