<template>
  <v-container class="d-flex justify-center">
    <progress-bar v-if="loading"></progress-bar>

    <WorkerReportManager v-else :groupedQuerylogs="groupedQuerylogs" :chart-colors="chartColors" />
  </v-container>
</template>

<script>
import { groupBy } from "lodash";
import WorkerReportManager from "@/components/supervision/reports/WorkerReportManager";
import supervisionClient from "@/services/supervisionClient";

import { Labels } from "@/helpers/dataLabels.js"

export default {
  components: { WorkerReportManager },
  props: {
    querylogs: {
      type: Array,
      default: () => [],
    },
    workerId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      groupedQuerylogs: {
        reopened: {},
        pending: {},
        submitted: {}
      },
      counter: 0,
      loading: false
    };
  },
  computed: {
    groupedQuerylogsByStatus() {
      return groupBy(this.querylogs, "finalStatus");
    },
    chartColors() {
      return {
        pending: { [this.workerId]: "#F5CAC3" },
        submitted: { [this.workerId]: "#84A59D" },
        reopened: { [this.workerId]: "#F28482" },
      };
    },
  },
  methods: {
    async getData(finalStatus) {
      try {
      const response = await supervisionClient
        .getUserQuerylogs(this.workerId , finalStatus)
        return response.data.queries
      } catch(error) {
        console.error(error);
      }

    }, 
    async groupQuerylogs() {
      this.loading = true;
      
      for (const datalabel of [ Labels.REOPENED, Labels.PENDING , Labels.SUBMITTED ]) {
          const data = await this.getData(datalabel);
          this.groupedQuerylogs[datalabel] = this.groupQuerylogsByWorker(data);
      }
      this.loading = false;
    },
    groupQuerylogsByWorker(querylogs = []) {
      return {
        [this.workerId]: querylogs,
      };
    },
  },
  created() {
    this.groupQuerylogs();
  },
};
</script>
