<template>
  <v-container fluid>

    <v-row v-for="finalStatus in finalStatuses" :key="finalStatus.status" class="ma-2">
      <v-col :key="refreshKey">
        <SupervisionQuerylogsTable
          :status="finalStatus.status"
          :headers="finalStatus.headers"
          :groupByHeaders="finalStatus.groupByHeaders"
          :workers="finalStatus.workers"
          @history="finalStatus.history"
          :title="finalStatus.title"
          :queryType="finalStatus.queryType"
          :actions="finalStatus.actions"
          :action-type-to-component-map="finalStatus.submitComponent"
          :wholeItem="finalStatus"
          :filter="finalStatus.filter"
          @updateTables="updateTables"
        />
      </v-col>
    </v-row>

    <v-dialog width="unset" class="d-flex" v-model="openHistory">
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title>Action history</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="openHistory = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <query-history :queryHistory="queryHistory" />
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import moment from "moment";
import { calcDiffBetweenDates } from "@/utils/dates";
import SupervisionQuerylogsTable from "./SupervisionQuerylogsTable";
import AssignQuerylogsForm from "./action-forms/AssignQuerylogsForm";
import DeleteQuerylogsForm from "./action-forms/DeleteQuerylogsForm";
import CloseQuerylogsForm from "./action-forms/CloseQuerylogsForm";
import SubmitStatusQuerylogsForm from "./action-forms/SubmitStatusQuerylogsForm";
import ApproveQuerylogsForm from "./action-forms/ApproveQuerylogsForm";
import SubmitQuerylogsForm from "./action-forms/SubmitQuerylogsForm";
import ReopenQuerylogsForm from "./action-forms/ReopenQuerylogsForm";
import queryHistory from "./query-history.vue";
import { Labels } from "@/helpers/dataLabels.js";

export default {
  components: {
    SupervisionQuerylogsTable,
    queryHistory,
  },
  props: {
    baseHeaders: {
      type: Array,
      default: () => [],
    },
    amlHeaders: {
      type: Array,
      default: () => [],
    },
    querylogs: {
      type: Array,
      default: () => [],
    },
    workers: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      refreshKey: 0,
      openHistory: false,
      queryHistory: null,
      groupByHeaders: [
        {
          text: "Assigned analyst",
          value: "workerName[0].username",
          sortable: false
        },
        {
          text: "Searched name",
          value: "searchedName",
        },
      ],
      finalStatuses: [
        {
          status: Labels.OPEN,
          headers: this.openQuerylogHeaders(),
          groupByHeaders: this.groupByHeaders,
          workers: this.workers,
          history: this.showHistory,
          title: "Opened",
          actions: this.openQuerylogActions(),
          submitComponent: this.openQuerylogsActionTypeToComponentMap()
        },
        {
          status: Labels.REOPENED,
          headers: this.reopenedQuerylogHeaders(),
          groupByHeaders: this.groupByHeaders,
          workers: this.workers,
          history: this.showHistory,
          title: "Reopened",
          actions: this.reopenedQuerylogActions(),
          submitComponent: this.openQuerylogsActionTypeToComponentMap()
        },
        {
          status: Labels.PENDING,
          headers: this.pendingQuerylogHeaders(),
          groupByHeaders: this.groupByHeaders,
          filter: true,
          history: this.showHistory,
          workers: this.workers,
          title: "Pending",
          actions: this.pendingQuerylogActions(),
          submitComponent: this.pendingQuerylogsActionTypeToComponentMap()
        },
        {
          status: Labels.SUBMITTED,
          headers: this.submittedQuerylogHeaders(),
          groupByHeaders: this.groupByHeaders,
          filter: true,
          workers: this.workers,
          history: this.showHistory,
          title: "Waiting for approval",
          actions: this.waitingQuerylogActions(),
          submitComponent: this.waitingQuerylogsActionTypeToComponentMap()
        },
        {
          queryType: "AML",
          filter: true,
          headers: this.amlHeaders,
          groupByHeaders: this.groupByHeaders,
          workers: this.workers,
          history: this.showHistory,
          title: "AML",
        },
      ],
      
    };
  },
  methods: {
    showHistory(item) {
      this.openHistory = !this.openHistory;
      this.queryHistory = item.submissionHistory;
    },
    openQuerylogHeaders() {
      const combinedHeaders = [...this.baseHeaders];

      combinedHeaders.splice(
        3,
        0,
      {
        text: "Time pending (hours)",
        value: "timePending",
      });

      return combinedHeaders;
    },
    reopenedQuerylogHeaders() {
      const combinedHeaders = [...this.baseHeaders];
      combinedHeaders.splice(0, 0, {
        text: "Assigned analyst",
        value: "workerId.username",
        sortable: false
      });
      combinedHeaders.splice(
        3,
        0,
        {
          text: "Submission History",
          value: "submissionHistory",
        }, 
        {
        text: "Time pending (hours)",
        value: "timePending",
        }
      );

      return combinedHeaders;
    },
    pendingQuerylogHeaders() {
      const combinedHeaders = [...this.baseHeaders];

      combinedHeaders.splice(3, 0, {
        text: "Time pending (hours)",
        value: "timePending",
      });

      combinedHeaders.splice(0, 0, {
        text: "Assigned analyst",
        value: "workerName[0].username",
        sortable: false
      });

      return combinedHeaders;
    },
    submittedQuerylogHeaders() {
      const combinedHeaders = [...this.baseHeaders];

      combinedHeaders.splice(0, 0, {
        text: "Assigned Analyst",
        value: "workerId.username",
        sortable: false
      });

      combinedHeaders.splice(
        4,
        0,
        {
          text: "Worker submission date",
          value: "updatedAt",
        },
        {
        text: "Time pending (hours)",
        value: "timePending",
        },
        {
          text: "Submission History",
          value: "submissionHistory",
        }
      );

      return combinedHeaders;
    },
    baseQuerylogActions() {
      return [
        // TODO: ----- querylogs changed to
        {
          title: "Submit",
          type: "submit",
          onSubmit: (querylogs, status, files, finalStatus) => this.$emit("submitWithStatus", querylogs, status, files, finalStatus),
        },
      ];
    },
    openQuerylogActions() {
      return [
        {
          title: "Assign to ...",
          type: "assign",
          args: {
            workers: this.workers,
          },
          onSubmit: (querylogs, worker, files) => this.$emit("assign", querylogs, worker, files),
        },
        // {
        //   title: "Close",
        //   type: "close",
        //   args: {
        //     workers: this.workers,
        //   },
        //   onSubmit: (querylogs, worker, files) => this.$emit("close", querylogs, worker, files),
        // },
      ];
    },
    reopenedQuerylogActions() {
      return [
        {
          title: "Assign to ...",
          type: "assign",
          args: {
            workers: this.workers,
          },
          onSubmit: (querylogs, worker, files) => this.$emit("assign", querylogs, worker, files),
        },
        // ...this.baseQuerylogActions(),
      ];
    },
    pendingQuerylogActions() {
      return [
        {
          title: "Assign to ...",
          type: "assign",
          args: {
            workers: this.workers,
          },
          onSubmit: (querylogs, worker, files) => this.$emit("assign", querylogs, worker, files),
        },
        ...this.baseQuerylogActions(),
      ];
    },
    waitingQuerylogActions() {
      return [
        {
          title: "Reopen",
          type: "reopen",
          args: {
            workers: this.workers,
          },
          onSubmit: (querylogs, worker, files) => this.$emit("reopen", querylogs, worker, files),
        },
        {
          title: "Approve",
          type: "approve",
          onSubmit: (querylogs, message, files) => {
            this.$emit("approve", querylogs, message, files);
          },
        },
        // ...this.baseQuerylogActions(),
      ];
    },
    openQuerylogsActionTypeToComponentMap() {
      return {
        assign: AssignQuerylogsForm,
        delete: DeleteQuerylogsForm,
        close: CloseQuerylogsForm,
      }
    },
      pendingQuerylogsActionTypeToComponentMap() {
      return {
        assign: AssignQuerylogsForm,
        submit: SubmitStatusQuerylogsForm,
      }
    },
    submittedQuerylogsActionTypeToComponentMap() {
      return {
        close: SubmitQuerylogsForm,
        reopen: ReopenQuerylogsForm,
      }
    },
    approvedQuerylogsActionTypeToComponentMap() {
      return {
        approve: ApproveQuerylogsForm,
        reopen: ReopenQuerylogsForm,
      }
    },
    waitingQuerylogsActionTypeToComponentMap() {
      return {
        assign: AssignQuerylogsForm,
        reopen: ReopenQuerylogsForm,
        submit: SubmitStatusQuerylogsForm,
        approve: ApproveQuerylogsForm,
      }
    },

    // this will rerender all tables every time the key is changes
    updateTables() {
      this.refreshKey += 1;
    }
  },
  computed: {
    openedQuerylogsItems() {
      return this.querylogsOpened;
    },
    
    reopenQuerylogsItems() {
      return this.querylogsReopened;
    },
    
    pendingQuerylogItems() {
      const currentTime = moment();
      return this.querylogsPending.map((pendingQuerylog) => ({
        ...pendingQuerylog,
        timePending: calcDiffBetweenDates(currentTime, pendingQuerylog.time),
      }));
    },
    
    
    submittedQuerylogItems() {
      const currentTime = moment();
      return this.querylogsSubmitted.map((submittedQuerylog) => ({
        ...submittedQuerylog,
        timePending: calcDiffBetweenDates(currentTime, submittedQuerylog.time),
      }));
    },
    ApproveQuerylogsForm() {
      return [
        {
          title: "Approve",
          type: "approve",
          onSubmit: (querylogs, message, files) => {
            this.$emit("approve", querylogs, message, files);
          },
        },
        {
          title: "Reopen",
          type: "reopen",
          onSubmit: (querylogs, message, files) => this.$emit("reopen", querylogs, message, files),
        },
      ];
    },
  },
};
</script>

<style>
.v-list-item.primary--text.v-list-item--active.v-list-item--link .v-list-item__title {
  color: #fff;
}
</style>
