<template>
  <v-container class="mt-12">
    <v-row class="mb-6">
      <v-col v-for="chart in chartsToDisplay" :key="chart" cols="4">
        <DoughnutChart
          :chart-data="chartsData[chart]"
          :title="capitalize(chart)"
          @click="displayQuerylogsData"
          :legend="getShowLegendValue(chart)"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <slot name="external-legend" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { pick } from "lodash";
import DoughnutChart from "@/components/supervision/reports/chart/DoughnutChart";
import shared from "@/utils/shared";

export default {
  components: { DoughnutChart },
  props: {
    groupedQuerylogs: Object,

    reopenQuerylogs: {
      type: Array,
      default: () => [],
    },
    pendingQuerylogs: {
      type: Array,
      default: () => [],
    },
    submitedQuerylogs: {
      type: Array,
      default: () => [],
    },
    chartColors: Object,
    legends: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      chartsData: {
        reopened: {},
        pending: {},
        submitted: {},
      },
    };
  },
  computed: {
    chartsToDisplay() {
      return Object.keys(this.chartsData).filter((type) => {
        if (
          this.chartsData[type]?.datasets[0]?.data[0] 
        ) {
          return this.chartsData[type];
        } else if (type === "timeframe") {
          return this.chartsData[type];
        }
      });
    },
  },
  methods: {
    capitalize(str) {
      return shared.capitalize(str);
    },
    createChartsData() {
      Object.keys(this.groupedQuerylogs).forEach((datalabel) => {
        const chartData = this.buildChartData(this.groupedQuerylogs[datalabel], datalabel);

        this.chartsData[datalabel] = chartData;
      });
    },
    buildChartData(querylogsMap, datalabel) {
      const keys = Object.keys(querylogsMap);

      if (keys.length === 0) {
        return null;
      }

      return {
        labels: keys,
        datasets: [
          {
            label: datalabel,
            backgroundColor: this.getColors(keys, datalabel),
            data: keys.map((key) => querylogsMap[key]?.length || 0),
          },
        ],
      };
    },
    getColors(keys, datalabel) {
      const colorsObj = pick(this.chartColors[datalabel], keys);
      return Object.values(colorsObj);
    },
    getShowLegendValue(datalabel) {
      return this.legends.includes(datalabel);
    },
    displayQuerylogsData(clickInfo) {
      const { index, datalabel } = clickInfo;
      let workerName = ""

      const querylogsByDataLabel = this.groupedQuerylogs[datalabel];
      const selectedQuerylogs = Object.values(querylogsByDataLabel)[index];
      workerName = selectedQuerylogs[0].workerId.username

      this.$router.push({
        name: "supervisionReportTable",
        query: { state: datalabel ? datalabel : "all" },
        params: {filterBy: workerName}
    
      });
    },
  },
  created() {
    this.createChartsData();
   
  },
};
</script>
