import { parseAsync } from "json2csv";
import { saveDownload } from "@/utils/files";
import supervisionClient from "@/services/supervisionClient";

const reportFields = [
  "queryId",
  "queryDate",
  "status",
  "queryName",
  "type",
  "assignedWorker",
  "assignmentDate",
  "workerSubmissionDate",
  "workerSubmissionStatus",
  "supervisorCloseDate",
  "openCount",
  "pendingCount",
  "submittedCount",
  "closedCount",
  "totalCount",
];

export default {
  methods: {
    async downloadDailyReport({ userRole, userId }) {
      const date = new Date().toISOString();
      const filename = `supervision_${userRole}_report_${userId}-${date}.csv`;

      const response = await supervisionClient.getDailyReport({ userRole, userId });

      response.data =
        userRole === "supervisor"
          ? await this.getSupervisorCsvDataReport(response.data)
          : await this.getWorkerCsvDataReport(response.data);

      await saveDownload(response, filename);
    },
    async getSupervisorCsvDataReport(data) {
      const openCount = data.open?.length || 0;

      const dataToAdd = { openCount };

      const modifiedOpenQuerylogs = this.reformatReportData({ open: data.open }, dataToAdd);

      if (!data.workers) {
        return this.parseJsonToCsv(modifiedOpenQuerylogs);
      }

      const modifiedWorkersQuerylogs = Object.values(data.workers)
        .map((workerGroupedQuerylogs) => this.reformatWorkerReportData(workerGroupedQuerylogs))
        .flat();

      const modifiedData = [...modifiedOpenQuerylogs, ...modifiedWorkersQuerylogs];

      return this.parseJsonToCsv(modifiedData);
    },
    async getWorkerCsvDataReport(data) {
      const modifiedData = this.reformatWorkerReportData(data);

      return this.parseJsonToCsv(modifiedData);
    },
    reformatWorkerReportData(data) {
      const pendingCount = data.pending?.length || 0;
      const submittedCount = data.submitted?.length || 0;
      const closedCount = data.closed?.length || 0;
      const totalCount = pendingCount + submittedCount + closedCount;

      const dataToAdd = {
        pendingCount,
        submittedCount,
        closedCount,
        totalCount,
      };

      return this.reformatReportData(data, dataToAdd);
    },
    reformatReportData(data, dataToAdd = {}) {
      return Object.values(data)
        .reduce((res, statusQuerylogsArray) => [...res, ...statusQuerylogsArray], [])
        .map((querylog) => ({
          ...querylog,
          ...dataToAdd,
        }));
    },
    async parseJsonToCsv(data) {
      return parseAsync(data, { fields: reportFields, delimiter: "|", quote: "" });
    },
  },
};
