<template>
    <v-container fluid>
        <v-row>
            <v-col>
                <h4>User managment</h4>
            </v-col>
            <v-col v-if="showAllAnalysts" class="text-right pr-4">
                <v-btn @click="loadWorkers()">All Analysts</v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <SupervisorsList
                    :supervisors="supervisors"
                    :workers="this.$store.getters.workers"
                    :userRole="userRole"
                    @modify="modifySupervisor"
                />
            </v-col>
            <v-col>
                <WorkersList
                    :supervisors="supervisors"
                    :workers="workersData"
                    :title="analystsTitle"
                    :userRole="userRole"
                />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import SupervisorsList from "@/components/supervision/users/supervisors/SupervisorsList";
import WorkersList from "@/components/supervision/users/workers/WorkersList";

export default {
    components: { SupervisorsList, WorkersList },
    data() {
        return {
            showAllAnalysts: false,
            analystsTitle: 'Analysts',
            workersData: [],
            userRole: localStorage.getItem("user-role"),
        };
    },
    computed: {
        supervisors() {
            return this.$store.getters.supervisors;
        },
    },

    methods: {
        setManagerName(workers) {
            const supervisors = this.supervisors;
            workers.forEach((worker) => {
                const supervisor = supervisors.find( supervisor => worker.managerId == supervisor.id );
                worker.managerName = supervisor?.username;
            });

            return workers;
        },
        loadWorkers(managerId = '') {
            const workers = this.$store.getters.workers;
            if (!managerId) {
                this.workersData = this.setManagerName(workers);
                this.analystsTitle = 'Analysts';
                this.showAllAnalysts = false;
            } 
            else {
                const filteredWorkers = workers.filter((worker) => {
                    if (worker.managerId === managerId) {
                        return worker;
                    }
                });

            this.workersData = this.setManagerName(filteredWorkers);
        }
        },
        async loadData() {
            let payload = { 
                managerId: this.$store.getters.userId,
                tenantId: this.$store.getters.tenantId
            };      

            await this.$store.dispatch('setSupervisors', payload);
            await this.$store.dispatch('setWorkers', {tenantId: this.$store.getters.tenantId});
            this.loadWorkers();
        },

        async modifySupervisor(type, supervisor) {
            if (type === 'expand') {
                this.loadWorkers(supervisor.id);
                this.analystsTitle = supervisor.username;
                this.showAllAnalysts = true;
            }
        }
    },
    created() {
        this.loadData();
    },
};
</script>
