<template>
  <div>
  <v-alert v-if="isMessage" :color="msgType" dark> {{ messageText }} </v-alert>
  <v-card>
    <CRUDTable
      title="Users"
      :headers="headers"
      :items="items"
      itemKey="username"
      :userRole="userRole"
      @delete="deleteItem"
      @edit="editItem"
      @add="addItem"
    >
      <template v-slot:editDialog="{ item }">
        <UserEditForm :value.sync="item" />
      </template>
    </CRUDTable>
  </v-card>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import CRUDTable from "@/components/common/CRUDTable";
import UserEditForm from "@/components/users/UserEditForm";

const { mapActions, mapGetters } = createNamespacedHelpers("users");

export default {
  name: "UserList",
  components: { UserEditForm, CRUDTable },
  props: {
    userRole: String,
  },
  data() {
    return {
      isMessage: false,
      messageText: '',
      msgType: 'success',
      headers: [
        {
          text: "User",
          value: "username",
        },
        {
          text: "First name",
          value: "firstname",
        },

        {
          text: "Last name",
          value: "lastname",
        },
        {
          text: "Role",
          value: "role",
        },
        // { g
        //   text: "ListType",
        //   value: "listType",
        // },
        {
          text: "Suspended?",
          value: "suspended",
        },
        {
          text: "Actions",
          value: "action",
          sortable: false,
        },
      ],
    };
  },

  computed: {
    ...mapGetters({ items: "userList" }),
  },
  methods: {
    ...mapActions({
      getList: "READ_LIST_user",
      addUser: "CREATE_user",
      deleteUser: "DELETE_user",
      updateUser: "UPDATE_user",
    }),
    addItem(item) {
      if (!item.password) {
        alert("Invalid username or password.");
        return;
      }
      this.addUser(item).then(
          (value) => { 
            // console.log('-========= value =============-', Object.keys(value), value);
            if (value?.response?.request?.status == 500) {
              // console.log('=-=-=-=-=-=-=-=',JSON.parse(value.response.request.responseText).message);
              this.showMessage('error', JSON.parse(value.response.request.responseText).message);
            } 
            else {
              this.showMessage('success', `User created: ${value.role} ${value.username}`);
            } 
          }
      )
    },
    editItem(item) {
      this.updateUser(item);
    },
    deleteItem(item) {
      if (confirm(`Delete user ${item.username}?`)) {
        this.deleteUser(item);
      }
    },
    showMessage(msgType, msgText) {
      this.isMessage = true;
      this.messageText = msgText;
      this.msgType = msgType;
      setTimeout(this.hideMessage, 5000);
    },
    hideMessage(){
      this.isMessage = false;
      this.messageText = '';
      this.msgType = '';
    }
  },
  created() {
    this.getList();
  },
};
</script>
