<template>
  <progress-bar v-if="!ready"></progress-bar>
  <SupervisorReportManagerWrapper v-else :querylogs="querylogs" :persistanceLogs="persistanceLogs" :workers="workers" />

</template>

<script>
import { mapGetters } from "vuex";
import SupervisorReportManagerWrapper from "@/components/supervision/reports/SupervisorReportManagerWrapper";
import supervisionClient from "@/services/supervisionClient";
import { Labels } from "@/helpers/dataLabels.js"

export default {
  components: {
    SupervisorReportManagerWrapper,
  },
  data() {
    return {
      workers: [],
      querylogs: [],
      persistanceLogs: [],
      ready: false,
    };
  },
  computed: {
    ...mapGetters({
      loggedinSupervisorId: "userId",
    }),
  },
  methods: {
    async loadData() {
      this.supervisorWorkers = [];
      this.querylogs = [];

      const response = await supervisionClient.getStatisticForChiefOrSupervisor(
        {
          user: this.loggedinSupervisorId,
          status: "",
        }
      );

      this.workers = response.data;
      this.querylogs = response.data.filter(
        (query) => query.finalStatus !== Labels.CLOSED
      );

      this.ready = true;
    },
  },
  async created() {
    this.workers = await this.$store.dispatch({
      type: "getSupervisorWorkers",
      supervisor: this.loggedinSupervisorId,
    });
    await this.loadData();
  },
};
</script>
