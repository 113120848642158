<template>
  <v-form @submit.prevent="submit">
    <v-card>
      <v-card-title> Submit querylogs </v-card-title>
      <v-card-text class="mt-n3">
        <v-text-field label="Reason for submission" :items="statuses" v-model="submissionReason" />
      </v-card-text>

      <v-card-text>
        <v-select
          label="Status"
          :items="statuses"
          v-model="selectedStatus"
        />
      </v-card-text>


      <file-input-multiple @getFiles="getFiles" @noFiles="noFiles"></file-input-multiple>
      <v-card-actions>
        <v-spacer />
        <v-btn color="secondary" text @click="close"> Cancel </v-btn>
        <v-btn color="primary" :disabled="selectedStatus == '' || submissionReason.length < 5" type="submit">
          Submit {{ selectedStatus }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import fileInputMultiple from "./file-input-multiple.vue";
export default {
  components: { fileInputMultiple },
  props: {
    data: {
      type: Object,
      default: () => ({
        selectedQuerylogs: [],
      }),
      isWorkers: Boolean,
    },
  },
  data() {
    return {
      submissionReason: "",
      submittedfiles: [],
      selectedStatus: '',
    };
  },
  methods: {
    noFiles() {
      this.submittedfiles = [];
    },
    getFiles(files) {
      this.submittedfiles = files;
    },
    submit() {
      // TODO: MAY BE ???? this.data.selectedQuerylogs.forEach((selectedQuerylog) => selectedQuerylog.status = this.selectedStatus);
      this.$emit("submit", [
        this.data.selectedQuerylogs,
        this.submissionReason,
        this.submittedfiles,
        this.selectedStatus
      ]);

      this.close();
    },
    close() {
      this.submissionReason = '';
      this.selectedStatus = '';
      this.$emit("close");
    },
  },
  computed: {
    statuses() {
      if (!this.isWorkers) {
        return ["verified", "flagged"];
      } else {
        return [];
      }
    },
  },
};
</script>
