<template>
  <v-container fluid>
    <v-card class="ma-3">
      <v-card-text>
        <v-row class="pa-2">Version ID: {{ summaryReport.versionId }}</v-row>
        <v-row class="pa-2">Created: {{ summaryReport.createdAt }}</v-row>
        <v-row class="pa-2">Last used: {{ summaryReport.lastUsed }}</v-row>
      </v-card-text>
    </v-card>
    <v-card class="ma-3">
      <v-data-table
        :headers="headers"
        :items="summaryReport.dataSources"
        item-key="sourceId"
        show-expand
        dense
        disable-pagination
        hide-default-footer
      >
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length" class="pa-0">
            <v-col>
              <DataSource v-model="summaryReport.summaryReport.byDataSources[item.sourceId]" />
            </v-col>
          </td>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import DataSource from "@/components/datasources/DataSource";

export default {
  components: { DataSource },
  computed: {
    ...mapGetters({ summaryReport: "summaryReport" }),
  },
  data() {
    return {
      headers: [
        {
          text: "Id",
          value: "sourceId",
        },
        {
          text: "Display Name",
          value: "displayName",
        },
        {
          text: "Section",
          value: "section",
        },
        {
          text: "Category",
          value: "category",
        },
        {
          text: "Entities, #",
          value: "entities",
          align: "end",
        },
      ],
    };
  },

  methods: {
    ...mapActions({ loadData: "READ_summaryReport" }),
  },
  created() {
    this.loadData();
  },
};
</script>

<style scoped></style>
