<template>
  <div>
    <v-list>
      <v-list-item v-for="(item, i) in queryHistory" :key="i">
        <v-card
          class="ma-2"
          style="max-width: 1250px; min-width: 600px"
          :style="[
            i % 2
              ? { backgroundColor: 'rgb(223,223,223)' }
              : { backgroundColor: 'rgb(245,245,245)' },
          ]"
        >
          <v-card-text style="font-size: 12px" class="py-1 mb-n6 text-right">
            {{
              new Date(item.time).toLocaleString("en-GB", { timeZone: "UTC" }).slice(0, -3)
            }}</v-card-text
          >
          <v-card-text class="mb-n7"
            ><b>Previous Status: </b> {{ capitalize(item.fromStatus) }}</v-card-text
          >
          <v-card-text class="mb-n7"><b>Current Status: </b> {{ item.status }}</v-card-text>
          <v-card-text class="mb-n7"><b>Committed by: </b> {{ item.user }}</v-card-text>
          <v-card-text class="mb-n5"><b>Comments: </b>{{ item.message }}</v-card-text>
          <v-card-text class="d-flex justify-center" v-if="item.files.length">
            <v-btn @click="showFiles(item)" class="files"
              >Download {{ item.files.length > 1 ? "files" : "file" }}</v-btn
            >
          </v-card-text>
          <v-card-text v-else>No files attached</v-card-text>
        </v-card>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import shared from "@/utils/shared";

export default {
  props: {
    queryHistory: Array,
  },
  methods: {
    capitalize(str) {
      return shared.capitalize(str);
    },
    showFiles(item) {
      item.files.forEach((file) => {
        const fileBuffer = Buffer.from(file[0].buffer);
        var b64 = Buffer.from(fileBuffer).toString("base64");
        var binaryString = window.atob(b64);
        var binaryLen = binaryString.length;
        var bytes = new Uint8Array(binaryLen);
        for (var i = 0; i < binaryLen; i++) {
          var ascii = binaryString.charCodeAt(i);
          bytes[i] = ascii;
        }
        const linkSource = `data:application/pdf;base64,${binaryString}`;
        const downloadLink = document.createElement("a");
        const fileName = file[0].originalname;
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      });
    },
  },
  computed: {
    fileOrFiles(count) {
      if (count > 1) {
        return "files";
      } else {
        return "file";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.files {
  &:hover {
    cursor: pointer;
  }
}
</style>
