<template>
  <v-container fluid>
    <v-row v-for="finalStatus in finalStatuses" :key="finalStatus.status" class="ma-2">
      <v-col>
        <SupervisionQuerylogsTable
          :status="finalStatus.status"
          :headers="finalStatus.headers"
          :groupByHeaders="groupByHeaders"
          :workers="finalStatus.workers"
          @history="finalStatus.history"
          :title="finalStatus.title"
          :actions="finalStatus.action"
          :action-type-to-component-map="pendingQuerylogsActionTypeToComponentMap"
          :wholeItem="finalStatus"
          :queryType="finalStatus.queryType"
        />
      </v-col>
    </v-row>

    <v-dialog width="unset" class="d-flex" v-model="openHistory">
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title>Action history</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="openHistory = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <query-history :queryHistory="queryHistory" />
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import SupervisionQuerylogsTable from "./SupervisionQuerylogsTable";
import SubmitQuerylogsForm from "./action-forms/SubmitQuerylogsForm";
import queryHistory from "./query-history.vue";
import { Labels } from "@/helpers/dataLabels.js";


export default {
  components: { SupervisionQuerylogsTable, queryHistory },
  props: {
    baseHeaders: {
      type: Array,
      default: () => [],
    },
    amlHeaders: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      groupByHeaders: [
        {
          text: "Assigned analyst",
          value: "workerName[0].username",
        },
        {
          text: "Searched name",
          value: "searchedName",
        },
      ],
      finalStatuses: [
        {
          status: Labels.REOPENED,
          headers: this.reopenedQuerylogHeaders(),
          groupByHeaders: this.groupByHeaders,
          workers: this.workers,
          history: this.showHistory,
          action: this.openQuerylogActions(),
          submitComponent: this.openQuerylogsActionTypeToComponentMap,
          title: "Reopened",
        },
        {
          status: Labels.PENDING,
          headers: this.pendingQuerylogHeaders(),
          groupByHeaders: this.groupByHeaders,
          filter: false,
          history: this.showHistory,
          workers: this.workers,
          action: this.pendingQuerylogActions(),
          submitComponent: this.pendingQuerylogsActionTypeToComponentMap,
          title: "Pending",
        },
        {
          queryType: "AML",
          filter: true,
          headers: this.amlHeaders,
          groupByHeaders: this.groupByHeaders,
          workers: this.workers,
          history: this.showHistory,
          title: "AML",
        },
      ],
      openHistory: false,
      queryHistory: null,
      pendingQuerylogsActionTypeToComponentMap: {
        submit: SubmitQuerylogsForm,
      },
      openQuerylogsActionTypeToComponentMap: {
        submit: SubmitQuerylogsForm,
      },
    };
  },
  methods: {
    showHistory(item) {
      this.openHistory = !this.openHistory;
      this.queryHistory = item.submissionHistory;
    },
    reopenedQuerylogHeaders() {
      const combinedHeaders = [...this.baseHeaders];
      combinedHeaders.splice(0, 0, {
        text: "Assigned analyst",
        value: "workerId.username",
        sortable: false
      });
      combinedHeaders.splice(3, 0, {
        text: "Submission History",
        value: "submissionHistory",
      }, 
      {
      text: "Time pending (hours)",
      value: "timePending",
      });

      return combinedHeaders;
    },
    pendingQuerylogHeaders() {
      const combinedHeaders = [...this.baseHeaders];

      combinedHeaders.splice(3, 0, {
        text: "Time pending (hours)",
        value: "timePending",
      });

      combinedHeaders.splice(0, 0, {
        text: "Assigned analyst",
        value: "workerName[0].username",
        sortable: false
      });

      return combinedHeaders;
    },
    pendingQuerylogActions() {
      return [
        {
          title: "Submit",
          type: "submit",
          onSubmit: (querylogs, reason, files) => this.$emit("submit", querylogs, reason, files),
        },
      ];
    },

    openQuerylogActions() {
      return [
        {
          title: "Submit",
          type: "submit",
          onSubmit: (querylogs, reason, files) => this.$emit("submit", querylogs, reason, files),
        },
      ];
    },
  },
};
</script>
