<template>
  <v-form @submit.prevent="$emit('submit', [data.selectedQuerylogs])">
    <v-card>
      <v-card-title> Are you sure you want to delete? </v-card-title>
      <v-card-actions>
        <v-spacer />
        <v-btn color="secondary" text @click="$emit('close')"> Cancel </v-btn>
        <v-btn color="primary" type="submit"> Submit </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => ({
        selectedQuerylogs: [],
      }),
    },
  },
};
</script>
