<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="6" class="pa-0">
        <v-text-field
          label="First Name"
          id="firstname"
          name="firstname"
          type="text"
          v-model.trim="value.firstname"
          :rules="[rules.required, rules.minLen(2)]"
          prepend-icon="person"
        />
      </v-col>
      <v-col cols="12" sm="6" class="pa-0">
        <v-text-field
          label="Last Name"
          id="lastname"
          name="lastname"
          type="text"
          v-model="value.lastname"
          :rules="[rules.required, rules.minLen(2)]"
          prepend-icon="person"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-text-field
        label="Email"
        type="email"
        v-model.trim="value.username"
        :rules="[rules.required, rules.email]"
        prepend-icon="email"
        autocomplete="off"
      />
    </v-row>
    <v-row>
      <!--
      <v-col class="pa-0">
      <v-text-field
        label="Password"
        :type="passwordFieldType"
        v-model.trim="normalizedPass"
        :rules="[rules.required, rules.minLen(6)]"
        prepend-icon="lock"
      />
    
      <button @click="switchVisibility" class="ml-2">
        <v-icon v-if="passwordFieldType === 'password'">visibility</v-icon>
        <v-icon v-if="passwordFieldType === 'text'">visibility_off</v-icon>
      </button>
    </v-col>
    -->
      <v-col class="pa-0">
        <v-text-field
          :type="passwordFieldVisible ? 'text' : 'password'"
          label="Password"
          v-model="normalizedPass"
          :rules="[rules.required, rules.minLen(6)]"
          :append-icon="passwordFieldVisible ? 'mdi-eye-off' : 'mdi-eye'"
          counter
          @click:append="passwordFieldVisible = !passwordFieldVisible"
          prepend-icon="lock"
        />
      </v-col>
    </v-row>
    <!-- Tenant list START -->
    <v-row v-if="userRole === 'SYS_ADMIN'">
      <!-- DROP DOWN -->
      <v-select
        v-if="tenantList() && !!!value.id"
        label="Tenant list"
        id="tenantId"
        name="tenantId"
        v-model.trim="value.tenantId"
        :items="tenantsList"
        :item-text="'name'"
        :item-value="'tenantId'"
        :rules="[rules.required]"
        prepend-icon="group_work"
        @change="hasManager()"
      >
        <template v-slot:item="{ item }">
          {{ item.name }}
        </template>
      </v-select>
      <!-- INPUT FOR ADMIN -->
      <v-text-field
        v-else-if="!tenantList() || !!value.id"
        label="Tenant ID"
        id="tenantId"
        name="tenantId"
        :readonly="!!value.id"
        type="text"
        v-model.trim="value.tenantId"
        :rules="[rules.required]"
        prepend-icon="group_work"
      />
    </v-row>

    <v-row v-if="userRole !== 'SYS_ADMIN'">
      <v-text-field
        label="Tenant ID"
        id="tenantId"
        name="tenantId"
        readonly
        type="text"
        v-model.trim="value.tenantId"
        prepend-icon="group_work"
      />
    </v-row>
    <!-- Tenant list END -->
    <v-row>
      <v-select
        label="Role"
        id="role"
        name="role"
        v-model.trim="value.role"
        :items="roleList"
        :rules="[rules.required]"
        :readonly="!!value.id || !value.tenantId"
        prepend-icon="assignment_ind"
        @change="hasManager()"
      >
        <template v-slot:item="{ item }">
          {{
            item.toLowerCase().charAt(0).toUpperCase() +
            item.toLowerCase().slice(1).replace("_", " ")
          }}
        </template>
      </v-select>
    </v-row>
    <!-- MANAGERS LIST FOR ANALYST -->
    <v-row>
      <v-col 
        v-if="(value.role === 'ANALYST' || value.role === 'SUPERVISOR') && showNoManagersError"
        class="pa-0 error text-center">
        NO MANAGERS FOUND - CREATE A MANAGER FIRST
        <v-text-field
          id="xxx"
          name="xxx"
          type="hidden"
          value=""
          :rules="[rules.required]"
        />
      </v-col>
      <v-select
        v-if="managersList.length && (value.role === 'ANALYST' || value.role === 'SUPERVISOR')"
        :label="value.role === 'ANALYST' ? 'Supervisors' : 'Chief Supervisor'"
        id="managerId"
        name="managerId"
        v-model.trim="value.managerId"
        :item-text="'username'"
        :item-value="'id'"
        :items="managersList"
        :rules="[rules.required]"
        prepend-icon="supervised_user_circle"
      >
        <template v-slot:item="{ item }">
          {{ item.username }}
        </template>
      </v-select>
    </v-row>

    <v-row v-if="value.id">
      <v-col class="pa-0">
        <v-checkbox
          label="Suspended"
          id="suspended"
          name="suspended"
          v-model.trim="value.suspended"
          prepend-icon="pause_presentation"
        ></v-checkbox>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import { required, minLen, email } from "@/utils/validations";
import supervisionClient from "@/services/supervisionClient";
import apiClient from "@/services/apiClient";

// import { values } from 'lodash';
const { mapGetters } = createNamespacedHelpers("users");

export default {
  props: {
    value: Object,
  },
  computed: {
    ...mapGetters({
      roleList: "roleList",
    }),
    roles() {
      return mapGetters.roleList.map((role) => role.toLowerCase());
    },
    normalizedPass: {
      get() {
        return this.checker;
      },
      set(value) {
        if (value == "*****") {
          value = "";
        } else if (value.includes("******") && value.length > 6) {
          value = value.slice(6);
        }
        this.checker = value;
        this.value.password = value;
      },
    },
  },
  data() {
    return {
      showNoManagersError: false,
      checker: "******",
      rules: {
        required,
        email,
        minLen,
      },
      password: null,
      passwordFieldType: "password",
      passwordFieldVisible: false,
      userRole: localStorage.getItem("user-role"),
      showTenantList: true,
      tenantsList: [],
      managersList: [],
      readyToSubmit: false
    };
  },
  methods: {
    hasManager(){
      // 1 check ROLE and TENANT ID
      if(!this.value.tenantId){
        alert('NO TENANT !!!');
      } else {
        if(this.value.role){
          const filters = {
            tenantId: this.value.tenantId
          };

          switch(this.value.role) {
            case 'ANALYST':
              filters.role = 'SUPERVISOR'
              break;
            case 'SUPERVISOR':
              filters.role = 'CHIEF_SUPERVISOR'
              break;
          }
          if (filters.role) {
            this.loadManagers(filters);
          } else {
            this.readyToSubmit = true;
          }
        }
      }
    },
    noManager(showError) {
      this.showNoManagersError = showError;
      // TODO: disable SUBMIT BUTTON !!!!
    },
    loadManagers(filters) {
      supervisionClient.getManagers(filters)
        .then((resp) => {
          this.managersList = resp.data;
          if (!resp.data.length) {
            this.noManager(true);
          } else {
            this.noManager(false);
          }
          // this.supervisorsList = resp.data.filter(
          //   (supervisor) => supervisor.tenantId === this.value.tenantId
          // );
        })
        // .catch(error => {console.log(error)})
        // .finaly(() => {
        //   console.log('AFTER SUPERVISORS lOADED');
        //   // LOADING FALSE
        // });
    },
    loadTenants() {
      apiClient.getTenants().then((resp) => {
        this.tenantsList = resp.data;
      });
    },
    tenantList() {
      return this.showTenantList;
    },
    switchVisibility() {
      this.passwordFieldType = this.passwordFieldType === "password" ? "text" : "password";
    },
  },
  async created() {
    this.checker = "******";
    if (this.userRole === "SYS_ADMIN") {
      this.loadTenants();
    } else {
      this.value.tenantId = localStorage.getItem("tenantId");
    }
  },
};
</script>

<style scoped>
.row {
  margin-bottom: 15px;
}
</style>
